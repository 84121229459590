import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import OddsTable, {clipboardMeetingRow} from '../Common/RCOddsTableCompact';
import OddsDropLegend from '../Common/OddsDropLegend';
import QTTQuickPick from '../Common/QTTQuickPick';
import RadioButtonSet from '../Common/RadioButtonSet';
import MobileTabSet from '../../Common/MobileTabSet';
import Investment from '../Common/Investment';
import InvestmentCalculator from '../Common/InvestmentCalculator';
import { GetSelectedBet, ClearBetSels, SetRandomBetSelect as SetBetSelect, SetBetSelectSingle, SetBetSelectBanker, SetSubType, RandomLeg } from '../Common/RacingBetline';
import { getComingleMsgs, isSellingStarted, isRunnerSellingStarted, getIsShowPmuText } from '../Common/Common';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import ComingleMsg from '../Common/ComingleMsg'
import {IOddsTable} from '../Common/IOddsTable';
import { RacingContext } from '../../Home/RacingPage';
import AddSlipBtn from '../Common/AddSlipBtn';
import { useWindowSize } from '../../Common/CommonHooks';
import RefreshTime from '../../Common/ui-components/RefreshTime';
import Loading from '../../Common/ui-components/Loading';
import useCookie from '../../Common/hooks/useCookie';

const betTypeData = [
    { type: "S", name1: 'LB_STYPE_S', disabled: false, checked: false },
    { type: "M", name1: 'LB_STYPE_M', disabled: false, checked: false },
    { type: "B", name1: 'LB_STYPE_B', disabled: false, checked: false },
    { type: "BM", name1: 'LB_STYPE_BM', disabled: false, checked: false },
    { type: "MB", name1: 'LB_STYPE_MB_1', name2: 'LB_STYPE_MB_2', disabled: false, checked: false }
]

const OddsQTT = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;

    const [betTypeValue, setBetTypeValue] = useCookie('betTypeValueQTT', "S", { expires: 365 * 2 });
    const [betTypeStat, setBetTypeStat] = useState(() => betTypeData.reduce((prev, cur) => {
        if(cur.type === betTypeValue) {
            prev.push({...cur, checked: true})
        } else {
            prev.push({...cur})
        }
        return prev
    },[]));

    const getSelectedBetType = () => {
        let tName = '';
        let tBetType = betTypeStat.filter(data => data.checked)[0];
        if (tBetType != null)
            tName = tBetType.type;
        return tName;
    }
    function getRCColumns() {
        return {
            no: true,
            banker1: getSelectedBetType() != "M",
            banker2: getSelectedBetType() != "M",
            banker3: getSelectedBetType() != "M",
            leg: true,
            horseName: true,
            rcNoteM: context.content.isPeNoteEnable,
            win: true,
            place: false,
            field: getSelectedBetType() != "S",
            banker1F: getSelectedBetType() == "MB",
            banker2F: getSelectedBetType() == "MB",
            banker3F: getSelectedBetType() == "MB"
        };
    };

    const getRCColumnLbls = () => {
        let lbl = {};
        switch (getSelectedBetType()) {
            case "S":
                lbl = { banker1: t('LB_RC_ODDS_1ST'), banker2: t('LB_RC_ODDS_2ND'), banker3: t('LB_RC_ODDS_3RD'), leg: t('LB_RC_ODDS_4TH') };
                if (i18n.language == "ch") {
                    lbl = {
                        banker1: <span className="verticalWord">{lbl.banker1}</span>,
                        banker2: <span className="verticalWord">{lbl.banker2}</span>,
                        banker3: <span className="verticalWord">{lbl.banker3}</span>,
                        leg: <span className="verticalWord">{lbl.leg}</span>
                    };
                }
                break;
            case "M":
                lbl = { leg: t('LB_RC_ODDS_SEL') };
                break;
            case "B":
            case "BM":
                lbl = { banker1: t('LB_RC_OODS_1ST_B'), banker2: t('LB_RC_OODS_2ND_B'), banker3: t('LB_RC_OODS_3RD_B'), leg: t('LB_RC_OODS_LEG_B') };
                if (i18n.language == "ch") {
                    lbl = {
                        banker1: <span className="verticalWord">{lbl.banker1}</span>,
                        banker2: <span className="verticalWord">{lbl.banker2}</span>,
                        banker3: <span className="verticalWord">{lbl.banker3}</span>,
                        leg: <span className="verticalWord">{lbl.leg}</span>
                    };
                }
                break;
            case "MB":
                lbl = { banker1: t('LB_RC_ODDS_1ST_MB'), banker2: t('LB_RC_ODDS_2ND_MB'), banker3: t('LB_RC_ODDS_3RD_MB'), leg: t('LB_RC_ODDS_4TH_MB') };
                if (i18n.language == "ch") {
                    lbl = {
                        banker1: <span className="verticalWord">{lbl.banker1}</span>,
                        banker2: <span className="verticalWord">{lbl.banker2}</span>,
                        banker3: <span className="verticalWord">{lbl.banker3}</span>,
                        leg: <span className="verticalWord">{lbl.leg}</span>
                    };
                }
                break;
        }
        return lbl;
    }

    const handleSubTypeRadioClick = (value) => {
        // let value = event.currentTarget.getAttribute("value");
        setBetTypeValue(value)
        let oldType = betTypeStat.filter(d => d.checked)[0].type;
        if (oldType == value) {
            return;
        }
        for (let idx in betTypeStat) {
            if (betTypeStat.hasOwnProperty(idx)) {
                betTypeStat[idx].checked = false;
                if (betTypeStat[idx].type == value) {
                    betTypeStat[idx].checked = true;
                }
            }
        }
        ClearBetSels();
        SetSubType(value);
        setBetTypeStat([...betTypeStat]);
        context.racingFuncs.resetInvCal();
    }

    const handleOddsClick = (_type, _race, _col, _no, val) => {
        switch (getSelectedBetType()) {
            case "S":
                SetBetSelectSingle(_type, _race, _col, _no, val);
                break;
            case "B":
            case "BM":
                SetBetSelectBanker(_type, _race, _col, _no, val);
                break;
        }
        SetSubType(getSelectedBetType());
        setBetTypeStat([...betTypeStat]);
    }

    const handleEnquiryKeyPadClick = (num, list) => {
        const betType = context.content.page;
        const raceNo = context.content.raceNo;
        if ( !isRunnerSellingStarted(betType, raceNo, num, mtg) )
            return;

        if ( list.indexOf(num)>=0 ) {
            SetBetSelectSingle(betType, raceNo, "b1", num, false);
            SetBetSelectSingle(betType, raceNo, "b2", num, false);
            SetBetSelectSingle(betType, raceNo, "b3", num, false);
            SetBetSelectSingle(betType, raceNo, "leg", num, false);
            setBetTypeStat([...betTypeStat]);
        }
        else {
            let arr1 = GetSelectedBet(betType, raceNo, "b1");
            if ( arr1.length==0 ) {
                SetBetSelectSingle(betType, raceNo, "b1", num, true);
                setBetTypeStat([...betTypeStat]);
                return;
            }
            arr1 = GetSelectedBet(betType, raceNo, "b2");
            if ( arr1.length==0 ) {
                SetBetSelectSingle(betType, raceNo, "b2", num, true);
                setBetTypeStat([...betTypeStat]);
                return;
            }
            arr1 = GetSelectedBet(betType, raceNo, "b3");
            if ( arr1.length==0 ) {
                SetBetSelectSingle(betType, raceNo, "b3", num, true);
                setBetTypeStat([...betTypeStat]);
                return;
            }
            arr1 = GetSelectedBet(betType, raceNo, "leg");
            if ( arr1.length==0 ) {
                SetBetSelectSingle(betType, raceNo, "leg", num, true);
                setBetTypeStat([...betTypeStat]);
                return;
            }
        }
    }

    const handleQTTRandom = (size, amt) => {
        const betType = context.contentRef.current.page;
        const raceNo = context.contentRef.current.raceNo;
        if ( !isSellingStarted(betType, raceNo, mtg.poolInvs) )
            return;

        ClearBetSels();
        for (let idx in betTypeStat) {
            if (betTypeStat.hasOwnProperty(idx)) {
                betTypeStat[idx].checked = false;
            }
        }
        betTypeStat[1].checked = true;

        let rList = RandomLeg(mtg, raceNo, size, []);
        for (let idx in rList) {
            if (rList.hasOwnProperty(idx)) {
                SetBetSelect(betType, raceNo, "leg", rList[idx], true);
            }
        }
        SetSubType("M");
        context.contentRef.current.unitBet = 1;
        context.contentRef.current.betTotal = amt;
        context.contentRef.current.flexibet = true;
        context.setContent({...context.contentRef.current});

        let line = document.querySelector('.mobile-tab-set > .line');
        let cur = document.querySelector('.subtype-M');
        line.style.left = cur.offsetLeft + "px";
        setBetTypeStat([...betTypeStat]);
        context.racingFuncs.resetInvCal();
    }

    if (context == null) {
        return <Loading/>
    } else {
        let tmpMsgs = getComingleMsgs(mtg, context.content.page, context.content.raceNo);
        let pageConfig = context.pageConfig;;

        const isSingle = getSelectedBetType()=='S';
        const isShowPmu = getIsShowPmuText(mtg, context.content.raceNo)
        let comingleMsg1 = [];
        let comingleMsg2 = [];
        if (tmpMsgs != null) {
            let comingleLang = i18n.language == "en" ? "E" : "C";
            if ( tmpMsgs['NOTE1_' + comingleLang] != '' )
                comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}1: </span>{tmpMsgs['NOTE1_' + comingleLang]}</div>);
            if ( tmpMsgs['NOTE2_' + comingleLang] != '' )
                comingleMsg2.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}2: </span>{tmpMsgs['NOTE2_' + comingleLang]}</div>);
            if ( tmpMsgs['NOTE3_' + comingleLang] != '' )
                comingleMsg2.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}3: </span>{tmpMsgs['NOTE3_' + comingleLang]}</div>);
        }

        const { isMobileSize } = useWindowSize();
        const columnsCountBreakPoints = isMobileSize ? { 350: 1, 1024: 2 } : { 350: 2 }

        context.racingFuncs.copyClipboardRightOddsTable = () => {
            return clipboardMeetingRow({ context, t, i18n, raceNo: context.content.raceNo, columns: getRCColumnLbls() });
        }

        return (
            <section className={context.content.page}>
                <Investment betType={context.content.page} />
                <InvestmentCalculator  className='border-radius-down' enable={!context.windowSize.isMobileSize} />
                {!context.windowSize.isMobileSize && <RefreshTime product="racing" />}
                <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints} style={{ margin: "12px 0px" }}>
                    <Masonry className="masonry-col">
                        <div>
                            <IOddsTable betType={context.content.page} isDisableEnquiry={!isSingle} msg={''}onEnquiryKeyPadClick={handleEnquiryKeyPadClick} />
                        </div>
                        <div>
                            <RadioButtonSet title={t('LB_' + context.content.page) + t('LB_RC_ODDS_METHOD')} items={betTypeStat} handleRadioClick={handleSubTypeRadioClick} handleQTTRandom={handleQTTRandom} />
                            <MobileTabSet items={betTypeStat} handleRadioClick={handleSubTypeRadioClick} />
                            <QTTQuickPick handleQTTRandom={handleQTTRandom} />
                            <OddsTable betType={context.content.page} columns={getRCColumns()} lbl={getRCColumnLbls()} showAddSlip={false}
                                firstRace={context.content.raceNo} raceNo={context.content.raceNo}
                                selectedBetType={context.content.page} startCnt={0} oddsCheckboxClickCallback={handleOddsClick} />
                            {/* {comingleMsg2} */}
                            {isShowPmu && <div className="rc-comingle-remarks"><ComingleMsg></ComingleMsg></div>}
                        </div>
                        <div className='OddsDropLegend-Content-ge1280'><OddsDropLegend /></div>
                    </Masonry>
                </ResponsiveMasonry>
                {/* {isShowPmu && <div className="rc-comingle-remarks"><ComingleMsg></ComingleMsg></div>} */}
                <div className='OddsDropLegend-Content-lt1280'><OddsDropLegend /></div>
                <div className='AddSlipBtn-Content'><AddSlipBtn /></div>
                {context.windowSize.isMobileSize && <RefreshTime product="racing" />}
                <InvestmentCalculator  className='border-radius-down' enable={true} />
            </section>
        );
    }
}
export default OddsQTT;