import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';

import { isLogonHigh } from '../../BetSlip/LoginHooks';
import { FootballContext } from '../../Home/FootballPage';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { NewWindow, getDayOfWeekShort } from '../../Common/home-common';
import { toDateFormat, toTimeFormat, trimDate, trimTournament, isSellingStarted } from './Common';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { Scrollbars } from 'react-custom-scrollbars-2';
import spacerGif from '../../../info/Include/images/spacer.gif';
import { useWindowSize } from '../../Common/CommonHooks';
import { matchCompare } from '../Data/useFootballPush/common';
import useGetMatches from '../Data/useGetMatches';
import moment from 'moment';

const CustomScrollbars = (props) => {
    const { isMobileSize } = useWindowSize();
    return (
        <Scrollbars
            autoHide
            autoHideTimeout={2000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={32}
            autoHeightMax={isMobileSize ? 200 : 420}
            {...props}
        />
    );
};

export const MatchDropdown = ({ singleMatch, matchList, onClickResultDropdown}) => {

    const context = useContext(FootballContext);
    const { sessionServerTimeStamp } = useContext(GlobalContext);
    const { loadMatchQuery } = useGetMatches(context.contentRef, context.setContent);
    const matches = matchList || context.content?.listData?.matches;
    const tabType = matchList!=null ? "RESULT" : context.content.selection.type;
    const { isMobileSize } = useWindowSize();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const onClickAllOddsDropown = (matchId, url) => {
        if(!context?.content?.config.MixAllUpPages.includes(context?.content?.page))
        {
            let checkedLinesList = context?.content?.oddsSelection?.filter((item) => {
                return item.checked
            })
            if(checkedLinesList.length > 0) {
                if(!confirm(t('LB_FB_UNSAVE_BET_ALERT'))){
                    return;
                } else {
                    context?.clearOddsSelection?.()
                }
            }
        }
        // loadMatchQuery({ page: context.contentRef.current.page, matchIds: [matchId] });
        navigate(url + window.location.search)
    }

    const getTabs = () => {
        switch ( tabType ) {
            case 'ALL':
                 const searchMatchIds = context.content.listData?.matches?.map(match => match.id)
                return { searchMatchIds }
            case "DATE":
                return trimDate(matches, context.contentRef.current.offset, context.content.config, sessionServerTimeStamp).filter(x=> x.date===context.content.selection.value[0]);
            case "TOURN":
                return trimTournament(matches, context.content).filter(x=> context.content.selection.value.some(y=> x.tournIds.includes(y)));
        }
        return {};
    }

    const getMenuItem = () => {

        let itemList = [];
        let tabs = getTabs();

        matches.sort(matchCompare);

        let _matchesGroupByDate = matches.reduce((init, next) => {
            let key = next.kickOffTime.substring(0,10);
            init[key] = init[key] || [];
            init[key].push(next);
            return init;
        }, {});

        Object.keys(_matchesGroupByDate).forEach(idx => {
            let itemText = '';
            let _match = _matchesGroupByDate[idx].filter(x => {
                switch ( tabType ) {
                    case "ALL":
                        return tabs?.searchMatchIds?.includes(x.id)
                    case "DATE":
                        let mDate = x.matchDate.substring(0, 10);
                        return tabs[0].isOther ? new Date(mDate) >= new Date(tabs[0].date) : mDate===tabs[0].date;
                    case "TOURN":
                        return tabs.filter(tab=> tab.tournIds.includes(x.tournament.id)).length>0;
                    case "RESULT":
                        return true;
                }
                return false;
            });

            if (_match.length > 0)
                itemText = '======' + toDateFormat(idx, true, false) + '(' + getDayOfWeekShort(idx, t) + ') ' + t('LB_FB_MATCH') + '======';

            _match = _match.filter(x => x.id != singleMatch.id);

            if (_match.length > 0) {

                itemList.push(<Dropdown.Item eventKey={'selected-date-' + idx} key={`selected-date-` + idx} disabled={true} align='start' >
                    {itemText}
                </Dropdown.Item>);

                _match.map(function (item, index) {

                    let matchDate = toDateFormat(item.kickOffTime, false, true);
                    let matchId = item.id;
                    let frontEndId = item.frontEndId;
                    let tournamentShortName = item.tournament?.code;
                    let teamVs = <>{item.homeTeam['name_' + i18n.language]} VS {isMobileSize && <wbr/>}{item.awayTeam['name_' + i18n.language]}</>;
                    let teamVsText = `${item.homeTeam['name_' + i18n.language]} VS ${item.awayTeam['name_' + i18n.language]}`;
                    let url = "/" + i18n.language + "/" + context.state.Product + "/" + context.state.page.toLowerCase() + "/" + matchId;

                    let dropdownItem = <Dropdown.Item
                        eventKey={matchId + index}
                        value={matchId}
                        key={`${matchId}`} >

                        {
                            onClickResultDropdown ? <div onClick={() => onClickResultDropdown(matchId)} activeClassName="selected">
                                {matchDate} {frontEndId} {teamVs}
                            </div> :
                            <div onClick={() => { onClickAllOddsDropown(matchId, url) }} activeClassName="selected" title={`${matchDate} ${frontEndId} ${teamVsText}`}>
                                {matchDate} {frontEndId} {teamVs}
                            </div>
                        }
                    </Dropdown.Item>

                    itemList.push(dropdownItem);

                });
            }
        });

        return itemList;
    }

    let menuItem = getMenuItem()
    if (menuItem.length > 1) {
        return <>
                <DropdownButton
                    as={ButtonGroup}
                    key={`matchDropdown`}
                    id={`matchDropdown`}
                    variant={`matchDropdown`}
                    title={<>{t('LB_FB_SELECT_OTHER_MATCH')}<ArrowIcon direction='down' size={24}/></>}
                    align="start"
                    className={"matchDropdown-ddbtn"}
                >
                    <CustomScrollbars>
                    <Dropdown.Item eventKey="matchDropdown-header" value={``} key="matchDropdown-header" disabled={true} >
                        {t('LB_FB_SELECT_OTHER_MATCH')}
                    </Dropdown.Item>
                    {menuItem}
                    </CustomScrollbars>

                </DropdownButton>
            </>
    } else {
        return <></>
    }
}

export const isRefundSuspenedOrPayout = (state) => {
    switch (state) {
        case "Suspended":
        case "Refund":
        case "RefundBeforeSuspend":
        case "RefundBeforeClosed":
        case "ClosedAfterRefund":
        case "Payout":
        case "PayoutStarted":
        case "PayoutStopped":
        case "PayoutHold":
        case "StopPayout":
            return true;
        default:
            return false;
    }
}

export const isRefundSuspened = (state) => {
    switch (state) {
        case "Suspended":
        case "Refund":
        case "RefundBeforeSuspend":
        case "RefundBeforeClosed":
        case "ClosedAfterRefund":
            return true;
        default:
            return false;
    }
}

export const isMatchKickOff = (state) => {

    if (state == null) {
        return false;
    }

    switch (state) {
        case "PREEVENT":
        case "DEFINED":
            return false;
        default:
            return true;
    }
}

export const Remark = ({ betType }) => {

    const { t } = useTranslation();

    const getRemark = () => {
        let remark = t('LB_FB_REMARKS');
        switch (betType) {
            case "HDC":
                remark = t('LB_FB_REMARKS2');
                break;
            case "ALLODDS":
                remark = t('LB_FB_REMARKS_ALLODDS');
                break;
            case "FHH":
                remark = t('LB_FB_REMARKS_FHH');
                break;
        }
        return remark.replace('{0}', t('URL_FB_REMARK'));
    }

    return <div className='remark' dangerouslySetInnerHTML={{ __html: getRemark() }} />
}

export const goToAllOddsPage = (match, context, anchor, fromES) => {
    if(context.content.page === 'INPLAY_ALL' || context.content.page === 'ALLODDS'  ) return
    let isInplayPage = context.content.page === 'INPLAY';
    let page = isMatchKickOff(match.status) || isInplayPage ? 'inplay_all' : 'allodds';

    let url = `/${context.state.lang}/${context.state.Product}/${page}/${match.id}`;
    if ( anchor!=null ) {
        url += `#${anchor}`;
    }
    context.state.onPageChange(url, page, fromES==true, match.id);
}

export function getInplayState(status, extend = '') {
    let type = '';
    switch (status) {
        case 'FIRSTHALF':
            type = '1st';
            break;
        case 'SECONDHALF':
            type = '2nd';
            break;
        case 'FIRSTHALFCOMPLETED':
            type = 'ht';
            break;
        case 'FULLTIMECOMPLETED':
            type = 'ft' + extend;
            break;
        case 'MATCHENDED':
        case 'INPLAYMATCHENDED':
        case 'MATCHSUSPENDED':
            type = 'completed';
            break;
        case 'EXTRATIME':
        case 'FIRSTHALFET':
        case 'FIRSTHALFETCOMPLETED':
        case 'SECONDHALFET':
            type = 'et';
            break;
        case 'SECONDHALFETCOMPLETED':
            type = 'aet';
            break;
        case 'PENALTYKICK':
        case 'PENALTYKICKCOMPLETED':

            type = 'pso';
            break;
        default:
            type = 'off';
            break;
    }
    return type;
}


export const ClockIcon = ({match}) => {
    const { t, i18n } = useTranslation();
    let startingSell = isSellingStarted(match);
    let isKickOff = isMatchKickOff(match.status);

    let color = startingSell && isKickOff ? 'green' : 'red';
    let type = startingSell && isKickOff ? 'on' : 'off';
    let alt = '';
    type = getInplayState(match.status);
    alt = isKickOff && startingSell ? t('LB_FB_INPLAY_CLOCK_ALT_' + type.toUpperCase()) : t('LB_INPLAY_AVAILABLE_SOON');

    return <div title={alt} className={`icon_clock_${color} icon_clock_image`} />
}

export function InplayClock({ match, foPool, isMobileSize }) {

    const context = useContext(FootballContext);
    const { t, i18n } = useTranslation();

    let isInplayPool = false;
    let isKickOff = isMatchKickOff(match.status);
    let page = context.content.page;
    let isSingleMatchPage = context.content.config.SingleMatchPages.includes(page);
    let isMatchSpecials = context.content.config.SpecialPools.includes(page);
    let isAllUpDetails = context.content.config.MixAllUpDetailPages.includes(page);
    let inplayState = isSingleMatchPage ? toDateFormat(match.kickOffTime, false, true) : toTimeFormat(match.kickOffTime);
    let inplayStateStr = getInplayState(match.status).toUpperCase();
    inplayState = isKickOff ? t('LB_FB_INPLAY_' + inplayStateStr) : inplayState;
    if(!isMobileSize && inplayState === '下半場完') {
        inplayState=<>下半<br />場完</>;
    }

    if (page === 'INPLAY' || page === 'INPLAY_ALL') {
        isInplayPool = true
    } else {
        isInplayPool = match.poolInfo.inplayPools.length>0
    }

    let isSingleMatch = context.content.config.SingleMatchPages.includes(page);
    let isInplayPage = context.content.page === 'INPLAY';

    return <div className={`inplay-clock-con right-border`}>
        <div className={`inplay-clock ${!isMobileSize && !isSingleMatchPage && !isAllUpDetails && isInplayPool ? 'left-border-before' : ''}`}>
            {
            (isMobileSize && ((!isSingleMatchPage && !isAllUpDetails) || (!isKickOff && !isMatchSpecials))) && <div className='exepcted-stop-time'>{inplayState}</div>
            }
            {!isMobileSize && !isSingleMatchPage && !isAllUpDetails && <RunningCorner match={match} foPool={foPool}  />}
            <div className={`status-and-clock ${i18n.language=="ch" && inplayStateStr=="AET" ? 'aet' : ''}`}>
                {(!isMobileSize || (isMobileSize && (isSingleMatchPage || isAllUpDetails))) && isKickOff && <div className='exepcted-stop-time'>{inplayState}</div>}
                <div className='icon-clock'>
                    {isInplayPool && <ClockIcon match={match} />}
                </div>
            </div>
            {isMobileSize && !isSingleMatchPage && !isAllUpDetails && <RunningCorner match={match} foPool={foPool}  />}
            {isMobileSize && !isSingleMatch && !isAllUpDetails && <>
                <Venue singleMatch={match} lang={i18n.language} t={t} />
            </>}
            {isMobileSize && isInplayPage && <LiveInfo t={t} match={match} isMobileSize={isMobileSize} />}
            {isMobileSize && !isAllUpDetails && <TVChannel match={match} />}
        </div>
    </div>

}

export const RunningCorner = ({ match, foPool, hideTotalCorner, hideHomeAwayCorner }) => {

    const context = useContext(FootballContext);
    const { t } = useTranslation();
    let isKickOff = isMatchKickOff(match.status);
    //let startingSell = isSellingStarted(match, foPool);
    let CornerStartingSell = match.poolInfo.sellingPools.some(betType => ["CHL", "FCH","CHD", "FHC"].includes(betType)) ;
    // let FCHStartingSell = match.poolInfo.sellingPools.some("FCH") ;
    let ETCornerStartingSell = match.poolInfo.sellingPools.some(betType => ['ECH', 'ECD'].includes(betType)) && match.status.toUpperCase() != 'FULLTIMECOMPLETED' ;
    let startingSell = CornerStartingSell || ETCornerStartingSell

    let isInplay = foPool!=null ? foPool.inplay : match.poolInfo.inplayPools.length > 0;
    let showCorner = match.poolInfo.inplayPools.some( i => window.fbConfig.CornerPools.includes(i)) && window.fbPageConfig[context.content.page].showInplayMatch
    let showHomeAwayCorner = match.poolInfo.inplayPools.some(i => window.fbConfig.CornerHandicapPools.includes(i))
    let totalCorner = 0
    let { corner, homeCorner, awayCorner } = getRunningResult(match);
    totalCorner = isKickOff && isInplay && startingSell  ? `${corner}${`(${homeCorner}:${awayCorner})`}` : '---'

    if(totalCorner != '---'){
        if(foPool?.status != 'SELLINGSTARTED' && (hideTotalCorner || hideHomeAwayCorner)){
            totalCorner = '---'
        }else{
            if(hideTotalCorner){
                totalCorner = `${homeCorner}:${awayCorner}`
            }
            if(hideHomeAwayCorner){
                totalCorner = corner
            }
        }
    }

    return isKickOff && isInplay && showCorner ? <div className='corner-result'>
            <div className='icon-corner' title={t('LB_FB_CORNERS_TAKEN_SO_FAR')} />
            <span>{totalCorner}</span>
    </div> : null
}

export const TournImage = React.memo(({ code, name }) => {
    const { i18n } = useTranslation();
    const [requestImgNum,setRequestImgNum]=useState(0);
    return <img src={`${window.globalConfig.SITECORE_IMAGE_URL}${window.sitecorePath.FLAG_PATH_PREFIX}flag_${code}?sc_lang=en-US`} title={name}
        onError={({ currentTarget }) => {
            if(location.hostname === 'localhost') return
            if(requestImgNum>0){
                return false;
            }else{
                setRequestImgNum(prev=>prev+1)
            }
            currentTarget.onerror = null;
            currentTarget.src=`${spacerGif}`;
      }} />
});

export const Venue = ({ singleMatch, lang, t }) => {

    let venue = singleMatch.venue;
    if (venue != null) {
        let venueName = venue?.['name_' + lang];
        return <>
            <span className='icon-venue' title={t('LB_FB_NEUTRAL_GROUND').replace('{0}', venueName)}>
                <div title={t('LB_FB_NEUTRAL_GROUND').replace('{0}', venueName)} />
            </span>
        </>
    } else {
        return <></>
    }
}

export const getAvaibleMatchByDate = (odds, date) => {

    let tMatch = [];
    if (date.length > 1) {
        tMatch = odds?.matches.filter(
            x => new Date(x.matchDate.substring(0, 10)).getTime() >= new Date(date[0]).getTime()
                && new Date(x.matchDate.substring(0, 10)).getTime() <= new Date(date[date.length - 1]).getTime()
                && !isRefundSuspenedOrPayout(x.matchState)
        );
    } else {
        tMatch = odds?.matches.filter(x => x.matchDate.substring(0, 10) == date[0]);
    }

    return tMatch;
}

export const getAvaibleMatchByALL = (odds, date) => {

    let tMatch = [];

    tMatch = odds?.matches.filter(
        x => !isRefundSuspenedOrPayout(x.matchState)
    );

    return tMatch;
}

export const getAvaibleMatchByTournament = (odds, tName) => {

    let tMatch = []
    if (tName.length > 1) {
        tName.map((item, index) => {
            odds?.matches.filter(
                x => x.tournament.tName == item
                    && !isRefundSuspenedOrPayout(x.matchState)
            )
                .map((v, i) => { tMatch.push(v); })
        });
    } else {
        tMatch = odds?.matches.filter(x => x.tournament.tName == tName);
    }

    return tMatch;
}

export const getAvaibleMatch = (odds, selection, isNextDay) => {

    let matchList = [];
    let selectedVal = isNextDay ? [selection.nextDay.date] : selection.value;

    if (selection.value.length > 0) {
        switch (selection.type) {
            case "DATE":
                matchList = getAvaibleMatchByDate(odds, selectedVal);
                break;
            case "TOURN":
                matchList = getAvaibleMatchByTournament(odds, selectedVal);
                break;
            case "ALL":
                matchList = getAvaibleMatchByALL(odds, selectedVal);
                break;
            default:
                matchList = getAvaibleMatchByDate(odds, selectedVal);
        }
    }

    return matchList;
}

export const H2H = ({ matchId, tournId }) => {
    const { t } = useTranslation();
    const globalContext = useContext(GlobalContext);
    let url = window.globalConfig.STAT_CENTER_URL + t('URL_FB_H2H').replace('{0}', tournId).replace('{1}', matchId);
    let h2hEnabled = globalContext.globalState.commonCtrl?.headToHeadIconEnabled;

    return h2hEnabled ? <span className='image-H2H' onClick={() => NewWindow(url, '', 770, 500, 1, 1)} title={t('LB_FB_H2H')}></span> : null;

}

export const isMatchCompletedStatus = (status) => {
    return ["MATCHENDED", "FIRSTHALFCOMPLETED", "FULLTIMECOMPLETED",
    "SECONDHALFETCOMPLETED", "PENALTYKICK", "PENALTYKICKCOMPLETED", "INPLAYMATCHENDED"].includes(status);
}


export const Score = ({ match, foPool }) => {

    const { t } = useTranslation();
    let isKickOff = isMatchKickOff(match.status)
    let isET = isExtraTimeMatch(match);
    let isInplay = foPool==null ? match.poolInfo.inplayPools.length > 0 : foPool.inplay;
    let {homeScore, awayScore} = getRunningResult(match);

    if ( match.poolInfo.sellingPools.length==0 && !isMatchCompletedStatus(match.status)) {
        homeScore = '-';
        awayScore = '-';
    }
    let score = <div></div>
    if (isKickOff && isInplay) {
        score = <div>
            <span>{homeScore}</span>
            <span>{awayScore}</span>
        </div>
    } else {
        score = <div className="vs">{t('LB_FB_VS').toUpperCase()}</div>
    }

    return <span className={`score ${isET ? "et" : ""}`}>{score}</span>
}

export const FullTimeScore = ({ match, className }) => {

    const { t } = useTranslation();
    let {homeScore, awayScore} = getFullTimeResult(match);
    let isCompletedStatus = ["MATCHENDED", "FIRSTHALFCOMPLETED", "FULLTIMECOMPLETED",
        "SECONDHALFETCOMPLETED", "PENALTYKICK", "PENALTYKICKCOMPLETED", "INPLAYMATCHENDED"].includes(match.status);
    if ( match.poolInfo.sellingPools.length==0 && !isCompletedStatus ) {
        homeScore = '---';
        awayScore = '---';
    }
    let score = `(${t('LB_FB_INPLAY_FT_M')}: ${homeScore}:${awayScore})`;

    return <span className={className}>{score}</span>
}

export function callLiveCast(liveCastUrl, bId) {
    var u = liveCastUrl.replace('{0}', bId);
    NewWindow(u, '', 805, 550, 1, 1);
}

export function callWebTV(t, id) {
    let url = window.globalConfig.JCFW_URL +  t('URL_FB_WEBTV');
    if ( id ) {
        url += '?matchid=' + id;
    }
    NewWindow(url, '_blank', 805, 550, 1, 1);
}

export const WebTVIcon = ({id}) => {
    const context = useContext(FootballContext);
    const { t } = useTranslation();
    let webTvEnable = isLogonHigh() && sessionStorage.getItem('webTV')==='true';
    const isShowWebTVIcon = context.content.pageConfig?.showWebTVIcon && webTvEnable;
    return isShowWebTVIcon ? <div className="live-football-icon cursor-pointer right-border" onClick={() => { callWebTV(t, id) }}></div> : null
}

export const LiveInfo = ({ t, match, isMobileSize, onlyShowLiveInfo = false }) => {
    const globalContext = useContext(GlobalContext);
    const context = useContext(FootballContext);
    let webTvEnable = isLogonHigh() && sessionStorage.getItem('webTV')==='true';
    if ( match.id ) {
        webTvEnable &= context.webTVData?.data?.filter(x=>x.footballMatchId===match.id)[0]?.liveWebTvEnabled;
    }
    const isShowWebTVIcon =context.content.pageConfig?.showWebTVIcon && webTvEnable;
    let betRadarEnabled = globalContext.globalState.commonCtrl?.liveTIconEnabled;
    let betRadar = match.liveEvents.filter(x=> x.code.indexOf("BETRADAR")>=0)[0];
    let hasBetRadarId = betRadar?.id != null && betRadar?.id != "";

    let _url = t('URL_FB_LIVE_CAST')
    if(isMobileSize && !isShowWebTVIcon){
        return null;
    }
    //
    if(onlyShowLiveInfo) {
        return betRadarEnabled && hasBetRadarId ? <div className='icon-liveInfo-m' onClick={() => callLiveCast(_url, betRadar.id)}></div> : null
    }
    return <div className='live-info'>
        {isShowWebTVIcon ?<WebTVIcon id={match.id} />:null}
        {betRadarEnabled && hasBetRadarId && <div className='icon-liveInfo' onClick={() => callLiveCast(_url, betRadar.id)}></div>}
    </div>
}

export const TVChannel = ({  match, type='single' }) => {
    const {t, i18n} = useTranslation();
    const [showTVCDialog, setShowTVCDialog] = useState(false)
    const singleTVChannel= match.tvChannels?.[0];
    const isShowTVChannel=singleTVChannel != null;
    const [itemSelectId,setItemSelectId]=useState(null)

    const handleClickTVChannel = (e,id) => {
        if(id){
            if(id!=itemSelectId){
                setItemSelectId(id)
            }else{
                setItemSelectId(null);
            }
        }else{
            setShowTVCDialog(!showTVCDialog)
        }
        e.stopPropagation();
    }

    const handleLeaveTVInfo =()=>{
        setShowTVCDialog(false);
        setItemSelectId(null);
    }

    if(!isShowTVChannel){
        return null;
    }
    if(type==='list'){
        return <div className='tvChannelList'>
            {match.tvChannels.map((item,index)=>{
                return  <div className='tvChannelsContent' key={index+item?.code}>
                   <div className={`tvChannels ${match.id+index}`} onClick={(e)=>{handleClickTVChannel(e,match.id+index)}} onMouseLeave={handleLeaveTVInfo}>{item?.code}</div>
                   {itemSelectId== (match.id+index)&& <TVChannelDialog match={item} setItemSelectId={setItemSelectId} curId={match.id+index}type='list'/>}
                </div>
            })}
        </div>
    }

    return <div className='tvChannelsContent'>
        <div className={`tvChannels ${match.id}`} onClick={handleClickTVChannel} onMouseLeave={handleLeaveTVInfo}>
            {singleTVChannel?.code}
        </div>
        {showTVCDialog && <TVChannelDialog match={match} curId={match.id} setShowTVCDialog={setShowTVCDialog}/>}
    </div>;
}

const TVChannelDialog = ({match,setShowTVCDialog,curId,setItemSelectId,type}) => {
    const {t, i18n} = useTranslation();
    const {tvChannels}=match;
    const isListType=type==='list';
    const showInfo=isListType?[match]:tvChannels;
    useEffect(() => {
        const documentEle = document.getElementById('root');
        const listener = (e) =>{
            if(e.target.className === `tvChannels ${curId}`) return ;
            isListType?setItemSelectId(null):setShowTVCDialog(false);
            e.stopPropagation();
        }
        documentEle.addEventListener('mousedown', listener);
        documentEle.addEventListener('touchstart', listener);
        return () => {
            documentEle.removeEventListener('mousedown', listener);
            documentEle.removeEventListener('touchstart', listener);
        }
    }, [])
    return <div className='FBTVChannelDialog' >
        <div className='tvChannelDialogList'>
            {showInfo?.map((item,index)=>{
                return <div className='tvChannelitem' key={index+item.code}>
                    {`${item?.code}-${item?.['name_' + i18n.language]}`}
                </div>
            })}
        </div>
    </div>
}


export const MatchInfo = ({ context, singleMatch, foPool }) => {

    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContext);
    let frontEndId = singleMatch.frontEndId;
    let homeTeam = singleMatch.homeTeam['name_' + i18n.language];
    let awayTeam = singleMatch.awayTeam['name_' + i18n.language];
    let matchDate = toDateFormat(singleMatch.kickOffTime, false, true);
    let tournamentShortName = singleMatch.tournament.code;
    let tournamentName = singleMatch.tournament['name_' +  i18n.language];
    let isMobile = context.state.windowSize.isMobileSize;
    let isKickOff = isMatchKickOff(singleMatch.status);
    let isET = isExtraTimeMatch(singleMatch);
    let isNtsDefined = singleMatch?.poolInfo?.normalPools?.some(x=> context.content.config.NTSPools.includes(x.slice(0,3)) || singleMatch?.poolInfo?.ntsInfo?.length > 0 || singleMatch?.poolInfo?.entInfo?.length > 0);
    let inplayStatus = getInplayState(singleMatch.status).toUpperCase();
    const isShowCashOutTicket= singleMatch.esIndicatorEnabled
        && ((globalContext.globalState.commonCtrl.earlySettlementEnabled
        && globalContext.globalState.commonCtrl.earlySettlementIndicatorEnabled) || window.globalConfig.IS_PREVIEW);

    return <div className='match-info'>
        {
            isMobile ? <>
                <div>
                    <div className='team' onClick={()=> { goToAllOddsPage(singleMatch, context) }}>
                        <span className='home'>{homeTeam}</span>
                        <Score match={singleMatch} foPool={foPool} />
                        <span className='away'>{awayTeam}</span>
                    </div>
                </div>
                <div className="match-info-2nd-row-m">
                    <span>{frontEndId}</span>
                    <span>{tournamentName}</span>
                    {isET && <FullTimeScore match={singleMatch} className='match-info-2nd-row-ftScore-m'/>}
                    <div className='clock-row'>
                        <InplayClock match={singleMatch} foPool={foPool} isMobileSize={isMobile} />
                    </div>
                    <Venue singleMatch={singleMatch} t={t} lang={i18n.language} />
                    <H2H matchId={singleMatch.id} tournId={singleMatch.tournament.id} />
                    <RunningCorner match={singleMatch} foPool={foPool}  />
                    {isShowCashOutTicket ? <div className='tourn_es_icon_m' title={t('LB_CASHOUT')}></div> : null}
                    <TVChannel match={singleMatch} type={'list'} />
                </div>
                {isNtsDefined && <div className="nts-row-m">
                    <ScoringInformation match={singleMatch} />
                </div>}
            </> : <>
                <div>
                    <div className='match'>
                        <span>{t('LB_FB_EVENT_ID')}</span>
                        <span className='val'>{frontEndId}</span>
                    </div>
                    <div className='team' onClick={()=> { goToAllOddsPage(singleMatch, context) }} title={isKickOff ? t('LB_FB_IN_PLAY_BETTING') : t('LB_FB_TITLE_ALLODDS')}>
                        <span className='home'>{homeTeam}</span>
                        <Score match={singleMatch} foPool={foPool} />
                        <span className='away'>{awayTeam}</span>
                    </div>
                    {context.content.betType !== 'MSP' &&
                        <div className='time'>
                            <span>{t('LB_FB_EXPT_STOP_TIME')}</span>
                            <span className='val'>{matchDate}</span>
                        </div>
                    }
                </div>
                <div>
                    <div></div>
                    <div className='state'>
                        <span className='matchInfoTourn'><TournImage key={`flag_${tournamentShortName}`} code={tournamentShortName} name={tournamentName} /> {tournamentName}</span>
                        {isET && <FullTimeScore match={singleMatch} />}
                        <span><InplayClock match={singleMatch} foPool={foPool} isMobileSize={isMobile} /></span>
                        <Venue singleMatch={singleMatch} t={t} lang={i18n.language} />
                        <H2H matchId={singleMatch.id} tournId={singleMatch.tournament.id} />
                        <RunningCorner match={singleMatch} foPool={foPool}  />
                        {isShowCashOutTicket ? <div className='tourn_es_icon' title={t('LB_CASHOUT')}></div> : null}
                        <TVChannel match={singleMatch} type={'list'} />
                    </div>
                    {isNtsDefined ? <ScoringInformation match={singleMatch} /> : <div></div>}
                </div>
            </>
        }
    </div>
}

export const getMatchCountByDate = (matches, dateList, isTotal, pageNum, numOfItem) => {

    let count = 0;

    let tMatch = [];
    dateList.map((date, i) => {
        matches.map((item, index) => {
            let matchDate = item.matchDate.substring(0, 10);

            if (date === matchDate) {
                tMatch.push(matches)
                //count++;
            }

        })
    });

    if (isTotal) {
        return tMatch.length;
    } else {
        return tMatch.filter((item, index) => index >= (pageNum - 1) * numOfItem && index <= (pageNum * numOfItem - 1)).length;
    }

}


export const getMatchCountByTourn = (matches, tournList, isTotal, pageNum, numOfItem) => {

    let count = 0;

    let tMatch = [];
    tournList.map((date, i) => {
        matches.map((item, index) => {
            let tName = item.tournament.tName;

            if (date === tName) {
                tMatch.push(matches)
                //count++;
            }

        })
    });

    if (isTotal) {
        return tMatch.length;
    } else {
        return tMatch.filter((item, index) => index >= (pageNum - 1) * numOfItem && index <= (pageNum * numOfItem - 1)).length;
    }

}

export const getMatchCount = (selection, matches, isTotal, pageNum, numOfItem) => {

    const type = selection.type;
    const list = selection.value;

    let count = 0;

    switch (type) {
        case 'DATE':
        case 'ALL':
            count = getMatchCountByDate(matches, list, isTotal, pageNum, numOfItem);

            if (selection.nextDay?.date != null && selection.nextDay.date.length > 0) {
                count += getMatchCountByDate(matches, [selection.nextDay.date], isTotal, pageNum, numOfItem);
            }
            break;
        case 'TOURN':
            count = getMatchCountByTourn(matches, list, isTotal, pageNum, numOfItem);
            break;
        default:
            count = 0;
            break;
    }

    return count;
}


export const MatchCountLabel = ({ total, pageNum, maxPerPage }) => {

    const { t } = useTranslation();
    const context = useContext(FootballContext);
    const MaxPaginationLimit = maxPerPage || context.content.config.MaxPaginationLimit;
    const allMatchsCount = context?.contentRef?.current?.listData?.matches?.length || 0
    const startIdx = ((pageNum - 1) * MaxPaginationLimit) + 1;
    const endIdx = ((pageNum - 1) * MaxPaginationLimit) + MaxPaginationLimit > total ? total : ((pageNum - 1) * MaxPaginationLimit) + MaxPaginationLimit;
    const showAll =  (allMatchsCount <= total) || (context.content.selection.type === 'ALL') || (context.content.selection.type === 'SEARCH' && context.content.selection.value.length == 0)
    const totalSize =  total >=allMatchsCount  ? total : allMatchsCount
    const text = showAll ? t('LB_FB_MATCH_COUNT_ALL').replace("{0}", totalSize) :
        t('LB_FB_MATCH_COUNT').replace('{0}', total).replace('{1}', endIdx).replace('{2}', allMatchsCount)

    return <div>
        {
            text
        }
    </div>;
}

export const InplayMsg = ({ singleMatch }) => {

    const { t } = useTranslation();
    const context = useContext(FootballContext);

    const handleOnInplayClick = () => {

        let product = context.state.Product;
        let lang = context.state.lang

        let url = '/' + lang + '/' + product + '/inplay_all/' + singleMatch.id

        context.state.onPageChange(url, 'inplay_all', false, singleMatch.id)
    }

    return <div className='nopool'>
        <div className='inplaymsg'>
            <div onClick={handleOnInplayClick}>
                {t('LB_FB_ACCEPT_INPLAY_ONLY')}
            </div>
        </div>
    </div>
}

export function ScoringInformation({ match }) {
    const { t, i18n } = useTranslation();
    let itemList = [];

    const table = () => {
        let homeTeam = match.homeTeam['name_' + i18n.language] + ' (' + t('LB_FB_HOME') + ')';
        let awayTeam = match.awayTeam['name_' + i18n.language] + ' (' + t('LB_FB_AWAY') + ')';
        let ntsMap = { H: homeTeam, A: awayTeam, N: t('LB_FB_RESULT_NO_GOALS'), RFD: t('LB_FB_RFD') };

        let ntsInfo = match.poolInfo.ntsInfo;
        ntsInfo.sort((x,y) => { return parseInt(x.split(':')[0]) - parseInt(y.split(':')[0]); });
        let entInfo = match.poolInfo.entInfo;
        entInfo.sort((x,y) => { return parseInt(x.split(':')[0]) - parseInt(y.split(':')[0]); });

        if ( ntsInfo.length + entInfo.length == 0 ) {
            itemList.push([t('LB_FB_RESULT_NO_GOALS'), '-'])
        }
        else {
            ntsInfo.forEach(x=> {
                let info = x.split(':');
                let ngoal = `${info[0]}${i18n.language=="en" ? getNumberSuffix(info[0]) : ''}`;
                let key = t('LB_FB_N_GOAL').replace('{0}', ngoal);
                let val = ntsMap[info[1]];
                itemList.push([key, val])
            });
            entInfo.forEach(x=> {
                let info = x.split(':');
                let ngoal = `${info[0]}${i18n.language=="en" ? getNumberSuffix(info[0]) : ''}`;
                let key = `${t('LB_FB_N_GOAL').replace('{0}', ngoal)} [${t('LB_FB_INPLAY_ET')}]`;
                let val = ntsMap[info[1]];
                itemList.push([key, val])
            });
        }

        return <div className='scoring-info-table'>
            <div className='header' >
                {t('LB_SCORING_INFO')}
            </div>
            <div className='sub-header'>
                <div>{t('LB_FB_GOAL')}</div>
                <div>{t('LB_FB_RESULT_SCORING_TEAM')}</div>
            </div>
            <div className='content'>
                {
                    itemList.map((item) => {
                        return <>
                            <div>{item[0]}</div>
                            <div>{item[1]}</div>
                        </>
                    })
                }

            </div>
        </div>
    }

    let text = t('LB_SCORING_INFO')
    let textAlt = t('LB_SCORING_INFO_ALT')

    return <div className='scoring-info' title={textAlt}>
        <DropdownButton
            as={ButtonGroup}
            key={`DropdownButton-scoring-info`}
            id={`dropdown-variants-scoring-info`}
            variant={text}
            title={text}
            align="end"
        >
            {table()}
        </DropdownButton>

    </div>
}

export function isExtraTimeMatch(match) {
    switch (match.status) {
        case "FIRSTHALFET":
        case "FIRSTHALFETCOMPLETED":
        case "SECONDHALFET":
        case "SECONDHALFETCOMPLETED":
            return true;
        case "PENALTYKICK":
        case "PENALTYKICKCOMPLETED":
            return match.runningResultExtra!=null;
        default:
            return false;
    }
}

export function getRunningResult(match) {
    if ( !match ) {
        return {};
    }
    if ( isExtraTimeMatch(match) ) {
        if ( match.runningResultExtra!=null )
            return match.runningResultExtra;
    }
    else if ( isMatchKickOff(match.status) ) {
        if ( match.runningResult!=null )
            return match.runningResult;
    }
    return { homeScore: 0, awayScore: 0, corner: 0, homeCorner: 0, awayCorner: 0 }
}

export function getFullTimeResult(match) {
    if ( match.runningResult!=null ) {
        return match.runningResult;
    }
    return { homeScore: 0, awayScore: 0, corner: 0 }
}

export function getNumberSuffix(_intGoal) {
    var intGoal = parseInt(_intGoal);
    var cntGoal = "th";

    if (intGoal < 11 || intGoal > 20) {
        if (intGoal % 10 == 1) {
            cntGoal = "st";
        } else if (intGoal % 10 == 2) {
            cntGoal = "nd";
        } else if (intGoal % 10 == 3) {
            cntGoal = "rd";
        }
    }
    return cntGoal;
}

export function getHandicap(foPool, config, isCloseLine, lineId=1) {
    if ( !config.HandicapPools.includes(foPool?.oddsType) )
        return { hG: '', aG: '' }

    let hg = [];
    let ag = [];
    // let cond = isCloseLine ? foPool.closedLines?.[0]?.condition : foPool.lines?.[0]?.condition;
    let cond;
    foPool[`${isCloseLine?'closedLines':'lines'}`]?.map(item=>{
        if(item.lineId === lineId){
            cond=item.condition;
        }
    })
    cond = cond || (isCloseLine ? foPool.closedLines?.find(line => line.lineId === lineId)?.condition : foPool.lines?.find(line => line.lineId === lineId)?.condition)
    if ( cond!=null ) {
        let splitConds = cond.split('/');
        splitConds.forEach(sCond => {
            let sCondF = parseFloat(sCond);
            if ( sCondF > 0 ) {
                hg.push('+' + sCondF);
                ag.push('-' + sCondF);
            }
            else if ( sCondF==0 ) {
                hg.push('0');
                ag.push('0');
            }
            else {
                sCondF = sCondF * -1;
                hg.push('-' + sCondF);
                ag.push('+' + sCondF);
            }
        });
    }
    return { hG: `[${hg.join('/')}]`, aG: `[${ag.join('/')}]` };
}

export function getGoalLine(line) {
    let lineArr = [];
    let cond = line.condition;
    if ( cond!=null ) {
        let splitConds = cond.split('/');
        lineArr = splitConds.map(sCond => {
            return parseFloat(sCond);
        });
    }
    return lineArr.join('/');
}

export function getMatchById(matches, matchId) {
    if ( matches.length==0 )
        return null;
    let tmp = matches.filter(item=> item.id===matchId)[0];
    if ( tmp==null )
        return matches[0];
    return tmp;
}

export const getFoPool = (match, betType, etBetType, page) => {
    let foPool = null;
    let GSBetTypes = window.fbConfig.GSPools
    if ( match==null || match.foPools==null ) {
        return null;
    }
    if ( etBetType!=null ) {
        foPool = match.foPools.filter(x=> x?.oddsType===etBetType)[0];
    }
    if ( foPool==null ) {
        foPool = match.foPools.filter(x=> x?.oddsType===betType)[0];
    }
    if(GSBetTypes.includes(betType)){
        foPool = match.foPools.find(pool => pool?.oddsType===betType && (pool.lines[0].combinations?.some(comb => comb.status == 'AVAILABLE') || page == 'RESULTS'))
    }
    if (betType === 'GS') {
        foPool= match.foPools.filter(x=> GSBetTypes?.includes(x.oddsType) && x.lines[0].combinations?.some(comb => comb.status == 'AVAILABLE'))
    }
    return foPool;
}

export const getCombination = (line, combStr) => {
    return line?.combinations.filter(x=> x.str==combStr)[0];
}

const showDiffTime = 2 // minute

export const getOddsArrowCss = (oddsState, hide) => {
    if(hide) return ''
    const {oddsDiff, oddUpdTs} = oddsState
    const now = moment()
    const showDiff = oddsDiff != 0 && oddUpdTs && now.diff(oddUpdTs, 'second') <= (showDiffTime * 60)
    if(showDiff){
        if ( oddsDiff > 0 )
            return "oddsUp";
        else if ( oddsDiff < 0 )
            return "oddsDown";
    }
    return "";
}

export const FlagTournIcon = () => {
    const { t, i18n } = useTranslation();
    let localeLang = i18n.language =="en" ? "en-US" : "zh-HK";
    let url = `${window.globalConfig.SPECIAL_URL}${window.specialPages.FB_FLAG_TOURN.replace('{0}', localeLang)}`;
    return <a title={t('LB_LEAGUES_AND_TOURNAMENTS')} href={url} target='_blank'>
    <div className='icon_flag' ></div></a>
}

export const getIsCrossPoolAllup = (data) => {
    let isCrossPoolAllup = false
    for(let i=0; i<data.legs.length && !isCrossPoolAllup; i++){
        for(let j=0; j<data.legs.length; j++){
            if(data.legs[i].betType != data.legs[j].betType){
                isCrossPoolAllup = true
                break;
            }
        }
    }
    return isCrossPoolAllup
}

export const formatBetType = (bType) => {
    let typ = bType;
    switch (bType) {
        case "FHAD":
            typ = "FHA";
            break;
        case "HILO":
            typ = "HIL";
            break;
        case "CHLO":
            typ = "CHL";
            break;

        case "FCHLO":
            typ = "FCH";
            break;
        case "FHLO":
            typ = "FHL";
            break;
        case "HHAD":
            typ = "HHA";
            break;
        case "FCRS":
            typ = "FCS";
            break;
        case "MSPC":
            typ = "SPC";
            break;
        case "ETHAD":
            typ = "EHA";
            break;
        case "ETHDC":
            typ = "EDC";
            break;
        case "ETHILO":
            typ = "EHL";
            break;
        case "ETCHLO":
            typ = "ECH";
            break;
        case "ETCRS":
            typ = "ECS";
            break;
        case "ETTTG":
            typ = "ETG";
            break;
        case "ETNTS":
            typ = "ENT";
            break;
        case "FHDC":
            typ = "FHH";
            break;
        case "CHDC":
            typ = "CHD";
            break;
        case "FCHDC":
            typ = "FHC";
            break;
        case "ETCHDC":
            typ = "ECD";
            break;
        case "ETHHAD":
            typ = "EHH";
            break;
    }
    return typ;
}

export const isInplayMatch = (match) => {
    return match.foPools.some(pool => pool.inplay)
}

export const isMatchVoided = (match)=> {
    return match?.status?.toUpperCase().indexOf("VOID") >= 0;
}

export const isMatchSuspended = (match)=> {
    return match?.status?.toUpperCase().indexOf("MATCHSUSPENDED") >= 0;
}

export const isAllPoolRefund = (match) => {
    return match.foPools?.every(i => i.status.includes("REFUND"))
}

export const isPayOutOrRefund = (leg, match) => {
    let betType = formatBetType(leg?.betType)
    let isGoallinePool = window.fbConfig.GoalLinePools.includes(betType) || window.fbConfig.HandicapPools.includes(betType);
    let combSt = leg?.combinations?.every(i => !["AVAILABLE", "UNAVAILABLE", "PAYOUTSTOPPED"].includes(i.comb?.status))

    if(isGoallinePool){
        return leg?.combinations?.every(i => i.line?.status?.indexOf("REFUND") >= 0 || i.line?.status?.indexOf("PAYOUTSTARTED") >= 0 || combSt)
    }

    let pool = match?.foPools?.find(i => i.id == leg?.poolId);
    let poolSt = pool?.status || ''

    return poolSt.indexOf("REFUND") >= 0 || poolSt.indexOf("PAYOUTSTARTED") >= 0 || combSt;
}

export const isMatchEnded = (match) => {
    return match?.status?.toUpperCase().indexOf("MATCHENDED") >= 0;
}

export const isMultipLineHasSinglePayOut = (leg) => {
    let betType = formatBetType(leg?.betType)
    let isGoallinePool = window.fbConfig.GoalLinePools.includes(betType) || window.fbConfig.HandicapPools.includes(betType);
    let combSt = leg?.combinations?.every(i => !["AVAILABLE", "UNAVAILABLE", "PAYOUTSTOPPED"].includes(i.comb?.status))
    if(isGoallinePool){
        return leg?.combinations?.find(i => i.line?.status?.indexOf("REFUND") >= 0 || i.line?.status?.indexOf("PAYOUTSTARTED") >= 0 || combSt)
    }
    return false;
}

export const isNorMatchKickOff = (match) => {
    return match?.status?.toUpperCase().indexOf("KICKEDOFF") >= 0;
}

export const isSettledLeg = (leg, match) => {
    return match && ( isMatchEnded(match) || isPayOutOrRefund(leg, match));
}


export const isStopSellLeg = (match) => {
    return match && ( isMatchEnded(match) || isNorMatchKickOff(match) || isMatchSuspended(match) || isMatchVoided(match));
}

export const isAllLegSettled = (legs) => {
    for (let idx in legs) {
        if (!legs[idx].match) {
            return false;
        }
        if (!isSettledLeg(legs[idx], legs[idx].match))
            return false;
    }
    return true;
}

export const isIrrationalAll = (leg) => {
    for (let combIdx in leg.combinations) {
        if (leg.combinations[combIdx].wageringStatus != "Irrational") 
            return false;
    }
    return true;
}

export const isESInplayDelay = (data)=>{
    let nonSettleLegCnt = 0;
    let nonSettleNonkickOffCnt = 0;
    data?.legs?.forEach(item => {
        if (!isSettledLeg(item, item.match)) {
            if (isMatchKickOff(item.match?.status)) {
                nonSettleLegCnt++;
            }
            else{
                nonSettleNonkickOffCnt++;
            }
        }
    })
    return nonSettleLegCnt == 1 && nonSettleNonkickOffCnt == 0;
}

export const anyWinComb = (leg)=> {
    for (let combIdx in leg.combinations) {
        if (["Win", "HalfWinHalfDraw", "HalfDrawHalfWin"].includes(leg.combinations[combIdx].wageringStatus))
            return true;
    }
    return false;
}

export const isLosingCombAll = (leg)=> {
    for (let combIdx in leg.combinations) {
        if (!["Lose"].includes(leg.combinations[combIdx].wageringStatus))
            return false;
    }
    return true;
}

export const isUnsettledComb = (status) => {
    return ["Available", "Unavailable", "PayoutStopped"].includes(status);
}

export const isPendingForResult = (leg) => {
    for (let combIdx in leg.combinations) {
        if (isUnsettledComb(leg.combinations[combIdx].wageringStatus))
            return true;
    }
    return false;
}

export const isTtgPendingForResult = (leg)=> {
    const isTtgPool = window.fbConfig.TotalGoalsPools.includes(formatBetType(leg.betType));
    if (!isTtgPool){
        return false;
    }
    for (let combIdx in leg.combinations) {
        const curCombination = leg.combinations[combIdx];
        const isTtgThanSevenMoreWin = curCombination.provisionalWageringStatus == "Win" && curCombination.wageringStatus == "Irrational";
        if(isTtgThanSevenMoreWin){
            return true;
        }
    }
    return false;
}

export const isGoalLinePendingForResult = (leg)=> {
    // HandicapPools && GoalLinePools
    let isGoallinePool = [...window.fbConfig.GoalLinePools, ...window.fbConfig.HandicapPools].includes(formatBetType(leg.betType))
    if (!isGoallinePool){
        return false;
    }
    // first look hasAvailableComb, if true, return false ; second look hasResultComb 
    let hasUnsettledComb = false;
    let hasResultComb=false;
    let isFullTimeComb=false;
    for (let combIdx in leg.combinations) {
        const curCombination = leg.combinations[combIdx];
        let isWinOrLoseCombItem = curCombination.wageringStatus?.toUpperCase().indexOf("LOSE") >= 0 || curCombination.wageringStatus?.toUpperCase().indexOf("WIN") >= 0 || curCombination.wageringStatus?.toUpperCase().indexOf("DRAW") >= 0;
        if(isWinOrLoseCombItem && !hasResultComb){
            hasResultComb = true;
        }
        if(isUnsettledComb(curCombination.wageringStatus) && !hasUnsettledComb){
            hasUnsettledComb = true;
        }
        if(isFullTime(leg.match) && !isFullTimeComb){
            isFullTimeComb = true;
        }
    }
    return isFullTimeComb || (!hasUnsettledComb && hasResultComb);
}

export const isMutilpleLinePending = (leg)=> {
    let hasUnsettledComb = false;
    let CombNotOffer = false;
    for (let combIdx in leg.combinations) {
        let offerEarlySettlement = leg.combinations[combIdx].comb?.offerEarlySettlement;
        let wageringStatus = leg.combinations[combIdx].wageringStatus;
        if ("NotOffer" == offerEarlySettlement && !CombNotOffer) {
            CombNotOffer = true;
        }
        if(isUnsettledComb(wageringStatus) && !hasUnsettledComb){
            hasUnsettledComb = true;
        }
    }
    return CombNotOffer && hasUnsettledComb;
}

export const  isPayoutStopped = (leg)=> {
    for (let combIdx in leg.combinations) {
        if (["PayoutStopped"].includes(leg.combinations[combIdx].wageringStatus))
            return true;
    }
    return false;
}

export const isAnyPendingForResult = (legs, offer) => {
    if ( legs.length==1 )
        return false;
    
    let hasPendingForPayout = false;
    for (let idx=0; idx<legs.length; idx++ ) {
        if (legs[idx].match == null) {
            continue;
        }

        if ( !legs[idx].match?.esIndicatorEnabled ) {
            return false;
        }
        
        if ( 
            (isMatchVoided(legs[idx].match) && !isPayOutOrRefund(legs[idx], legs[idx].match)) || 
            isPayoutStopped(legs[idx]) || 
            (!offer && isPendingForResult(legs[idx])) || 
            isTtgPendingForResult(legs[idx]) || 
            isMutilpleLinePending(legs[idx]) ||
            (isGoalLinePendingForResult(legs[idx]) && !isPayOutOrRefund(legs[idx], legs[idx].match)) 
        ) {
            hasPendingForPayout = true;
        }
    }
    return hasPendingForPayout;
}

export const isCombESTempNotOffer = (legs)=> {
    let tempNotOffer = true;
    for (let idx in legs) {
        let tempCombNotOffer = true;
        for (let combIdx in legs[idx].combinations) {
            let st = legs[idx].combinations[combIdx].comb?.offerEarlySettlement;
            if ("Offer" == st) {
                tempCombNotOffer = false;
            }
        }
        tempNotOffer = tempNotOffer && (!isSettledLeg(legs[idx], legs[idx].match) && tempCombNotOffer);
    }
    return tempNotOffer;
}

export const isOfferAll = (leg)=> {
    for (let combIdx in leg.combinations) {
        let st = leg.combinations[combIdx].comb?.offerEarlySettlement;
        if ("NotOffer" == st && isUnsettledComb(leg.combinations[combIdx].wageringStatus)) {
            return false;
        }
    }
    return true;
}

export const isFirstHalf = (match)=> {
    return ["FIRSTHALF", "FIRSTHALFCOMPLETED"].includes(match.status);
}

export const isSecondHalf = (match)=> {
    return ["SECONDHALF", "FULLTIMECOMPLETED"].includes(match.status);
}

export const isExtraTime = (match)=> {
    return ["FIRSTHALFET", "FIRSTHALFETCOMPLETED", "SECONDHALFET", "SECONDHALFETCOMPLETED"].includes(match.status);
}

export const isPenaltyKick = (match)=> {
    return ["PENALTYKICK", "PENALTYKICKCOMPLETED"].includes(match.status);
}

export const isFullTime = (match)=> {
    return ["FULLTIMECOMPLETED", "SECONDHALFETCOMPLETED"].includes(match.status);
}

export const showRunningResult = (match)=> {
    return isFirstHalf(match) || isSecondHalf(match) || isExtraTime(match);
}

export const isInplayStage = (match)=> {
    return isFirstHalf(match) || isSecondHalf(match) || isExtraTime(match) || isPenaltyKick(match);
}

export const getETBetType = (betType, match) =>{
    let currentBetType = '';
    switch ( betType ) {
        case "HAD":
            currentBetType = "EHA"
            break;
        case "HHA":
            currentBetType = "EHH"
            break;
        case "HDC":
            currentBetType =  "EDC"
            break;
        case "HIL":
            currentBetType = "EHL"
            break;
        case "CHL":
            currentBetType =  "ECH"
            break;
        case "CHD":
            currentBetType =  "ECD"
            break;
        case "CRS":
            currentBetType =  "ECS"
            break;
        case "TTG":
            currentBetType = "ETG"
            break;
        default:
            currentBetType = '';
            break;
    }

    if(currentBetType) {
        let isET = isExtraTimeMatch(match)
        let isFT  = isFullTime(match)
        if(isET || (isFT && match.poolInfo.definedPools?.includes(currentBetType))) {
            return currentBetType
        }
    }
    return ''
}

// 以下調整為測試所用
// export const getOddsArrowCss = (oddsDiff) => {
//     // return "oddsUp";
//     if ( Math.random() > 0.65 )
//         return "oddsUp";
//     else if ( Math.random() < 0.35 )
//         return "oddsDown";
//     return "";
// }