import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RacingContext } from '../../Home/RacingPage';
import { getIsShowPmuText, getUnComingle } from '../Common/Common';
import JackpotAllocatedAmount from '../Common/JackpotAllocatedAmount'
import OddsTable from '../Common/RCOddsTable';
import OddsDropLegend from '../Common/OddsDropLegend';
import RefreshTime from '../../Common/ui-components/RefreshTime';
import Loading from '../../Common/ui-components/Loading';
import ComingleMsg from '../Common/ComingleMsg'
const PWin = (props) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const isShowPmu = getIsShowPmuText(mtg, context.content.raceNo)
    const isUnComingle =  getUnComingle(mtg, context.content.raceNo)

    const RCColumns = {
        alw: true,
        no: true,
        colour: true,
        horseName: true,
        rcNote: context.content.isPeNoteEnable,
        draw: true,
        wt: true,
        jocky: true,
        trainer: true,
        bodyWT: false,
        rtg: false,
        gear: false,
        last6: false,
        pwin0: true,
        pwin1: true,
        win: true,
        place: true,
        wp: false
    };

    if (mtg == null) {
        return <Loading/>
    } else if (!mtg.currentNumberOfRace) {
        return <div style={{marginTop: "10px", marginLeft: "8px"}} id='PWIN_MSG'>{t('LB_RC_PWIN_MSG')}</div>
    } else {
        return (
            <section className={context.content.page}>
                <RefreshTime product="racing" />
                {
                    (isShowPmu || isUnComingle) && <JackpotAllocatedAmount isUnComingle={isUnComingle}></JackpotAllocatedAmount>
                }
                <OddsTable columns={RCColumns} />
                {isShowPmu && <div className="rc-comingle-remarks"><ComingleMsg></ComingleMsg></div>}
                <OddsDropLegend />
            </section>
        );
    }
}
export default PWin;