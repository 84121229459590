import React, { useRef, useEffect, useState, useContext, createContext, forwardRef, useImperativeHandle } from 'react';
import $ from 'jquery';
import { Helmet } from 'react-helmet';
import { GetRacingPageConfigs } from '../Common/ConfigHelper';
import TopMenu from '../Menu/TopMenu';
import LeftMenu from '../Menu/LeftMenu';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";

import { useWindowSize, useUpdateRNotes } from '../Common/CommonHooks';
import RedirectPage from '../Racing/Common/RedirectPage';
import RacingTitle from '../Racing/Common/RacingTitle';
import MeetingButton from '../Racing/Common/MeetingButton';
import MeetingInfo from '../Racing/Common/MeetingInfo';
import JTComboSearch from '../Racing/Common/JTComboSearch';
import NoBettingAndVoidedMsgUI from '../Racing/Common/NoBettingAndVoidedMsgUI';
import { getAlupBetTypeList } from '../Racing/Common/AlupPageBase';
import { isAllUpPage, isPoolDefined, isFOPoolDefined, setDefaultDisplayOption,
    getPoolNotDefinedMsg, initAlupData, isRacingFOPage } from '../Racing/Common/Common';
import { ClearBetSels, ClearBetRaces, GetJTCSelectdRace, SetBetSelect, GetBetSels, SetBetSels } from '../Racing/Common/RacingBetline';
import Sticky from 'react-stickynode';
import Banner from '../Header/Banner';

import { isLocalMeeting } from '../Common/home-common';
import useRacingPush from '../Racing/Data/useRacingPush';
import { useDataRefresh } from '../Racing/Data/DataRefreshHooks';
import ImportantNotice from '../Header/ImportantNotice';
import FOMenu from '../Racing/FO/FO_Menu';
import { GlobalContext } from '../../contexts/GlobalContext';
import { isLogonHigh } from '../BetSlip/LoginHooks';
import useQueryString from '../Common/hooks/useQueryString';
import Loading from '../Common/ui-components/Loading';
import useRCDataRefresh from '../Racing/Data/useRCDataRefresh';
import useLoadMeeting from '../Racing/Data/useLoadMeeting';
import  MoblieHomePageTooltips from '../Common/ui-components/MobileHomePageTooltips'
import { Cookies } from '../Common/CookieHelper';

export const RacingContext = createContext();

const RacingPage = forwardRef((props, ref) => {
    // if(document.referrer.indexOf(window.globalConfig.JCRW_URL)>-1){
    //     window.location.replace(Cookies.getData('lastURL4e'));
    // }
    const globalContext = useContext(GlobalContext);
    const { isPushing } = globalContext.mqttClient;
    const curProduct = 'racing';
    const {page, date, venue, raceNo} = useParams();
    const queryString = useQueryString();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [FOSelectedBet, setFOSelectedBet] = useState({})
    const contentRef = useRef({
        config: window.racingConfig,
        pageConfig: null,
        page: page!=null && page!='' ? page.toUpperCase() : "HOME",
        date: date!=null ? date : '',
        venue: venue!=null ? venue : '',
        raceNo: raceNo!=null ? parseInt(raceNo) : 0,
        meetingList: null,
        meeting: null,
        isPresales: false,
        displayOpt: null,
        displayOpt2: null,
        update: null,
        isLoading: true,
        paraReady: false,
        subMethod: '',
        alupData : [],
        alupFormula: '',
        betCount: '-',
        unitBet: '-',
        betTotal: '-',
        flexibet: false,
        isLogon: false,
        isPeNoteEnable: false,
        rNotesData: {},
        raceCacheNo: 0,
        initLoading:true,
        setFOSelectedBet,
        calState: {
            unitBet: '-',
            betTotal: '-',
            flexibet: false,
        }
    });

    const [content, setContent] = useState(contentRef.current);
    const windowSize = useWindowSize();
    const updateNotes = useUpdateRNotes();

    const pushData = useRacingPush(contentRef);
    const { foUserContent, resetInvCal } = useRCDataRefresh(contentRef, setContent);
    const { manualReload, reloadRacingPools } = useDataRefresh(contentRef, setContent, resetInvCal);
    const { reloadMeeting } = useLoadMeeting(contentRef, setContent);

    useEffect(() => {
        globalContext.updateGlobalState({
            product: curProduct,
            betType: contentRef.current.page
        });
    }, [])
    useEffect(() => {
        globalContext.updateGlobalState({jtcPara: [], allupBoxChecked: false });
    }, [content.page])

    // for detect logon render
    useEffect(() => {
        contentRef.current.isLogon = isLogonHigh();
        contentRef.current.isPeNoteEnable = isLogonHigh() && window.globalConfig.ENABLE_PE_NOTE && globalContext.racingNotesData.globalEnable && globalContext.racingNotesData.settingEnable;
        if ( isLogonHigh() ) {
            globalContext.racingNotesData.loadNotesData();
        }
        setContent({... contentRef.current});
    }, [isLogonHigh(), updateNotes,  contentRef.current.page, globalContext.racingNotesData.globalEnable, globalContext.racingNotesData.settingEnable]);

    // for racing notes render
    useEffect(()=> {
        if ( globalContext.racingNotesData.noteData && globalContext.racingNotesData.noteData.data ) {
            contentRef.current.rNotesData = globalContext.racingNotesData.noteData.data;
            setContent({... contentRef.current});
        }
    }, [globalContext.racingNotesData.noteData]);

    useImperativeHandle(ref, () => ({
        handleAllUpClick: (e, flag, targetPage, context) => {
            handleAllUpClick(e, flag, targetPage, context);
        }
    }));

    const handleAllUpClick = (e, flag, targetPage, context) => {
        let isChecked = flag;
        if ( e )
            isChecked = e.target.checked;
        switch ( contentRef.current.page) {
            case "JTCOMBO":
                if (isChecked) {
                    let obj = GetJTCSelectdRace();
                    let tmpObj = obj.filter(x=> x.bTypes.length>1);
                    if ( obj.length > 6 ) {
                        alert(t('LB_RC_JTC_INVALID_RACES'));
                        globalContext.updateGlobalState({ allupBoxChecked: false });
                    }
                    else if (tmpObj.length > 0) {
                        alert(t('LB_RC_JTC_ONE_POOL_ONLY').replace('{0}', tmpObj[0].raceNo));
                        globalContext.updateGlobalState({ allupBoxChecked: false });
                    }
                    else {
                        $('.allupformula').show();
                        contentRef.current.alupData = [];
                        obj.forEach(x => {
                            contentRef.current.alupData.push({ raceNo: x.raceNo, betType: x.bTypes, subType: '', maxLeg: 6});
                        });

                        contentRef.current.alupFormula =  contentRef.current.config['ALUP_FORMULA'][obj.length][0];
                        setContent({... contentRef.current});
                        resetInvCal()
                        globalContext.updateGlobalState({ allupBoxChecked: true });
                    }
                }
                else {
                    $('.allupformula').hide();
                    contentRef.current.alupFormula = '';
                    setContent({... contentRef.current});
                    resetInvCal()
                    globalContext.updateGlobalState({ allupBoxChecked: false });
                }
                break;
            default:
                // SQ0248-1791 issue1 To compose an All Up bet, please select one pool type only.（eng）
                if (isChecked) {
                    let obj = GetJTCSelectdRace();
                    let tmpObj = obj.filter(x=> x.bTypes.length>1);
                    if (tmpObj.length > 0) {
                        alert(t('LB_RC_JTC_ONE_POOL_ONLY').replace('{0}', tmpObj[0].raceNo));
                        globalContext.updateGlobalState({ allupBoxChecked: false });
                        return;
                    }
                }
                let url = `/${i18n.language}/racing/${targetPage.toLowerCase()}/${ contentRef.current.date}/${ contentRef.current.venue}/${ contentRef.current.raceNo}`;
                handleChangePage(url, targetPage, targetPage.indexOf('ALUP')>=0);
                break;
        }
    }

    const handleVenueClick = (dt, ve) => {
        ClearBetSels();
        contentRef.current.date = dt;
        contentRef.current.venue = ve;
        contentRef.current.raceNo = 0;
        contentRef.current.update = Math.random();
        contentRef.current.isLoading = true;
        contentRef.current.alupData = [];
        contentRef.current.alupFormula = "";
        contentRef.current.isPresales = false
        contentRef.current.displayOpt = null;
        setContent({... contentRef.current});
        globalContext.resetGlobalState();
    }


    const handleRaceNoClick = (context, _raceNo) => {
        if (isAllUpPage( contentRef.current.page)) {
            if ( contentRef.current.alupData.length === 1 &&  contentRef.current.alupData[0].raceNo === _raceNo) return;
            let isSelected =  contentRef.current.alupData.filter(x => x.raceNo == _raceNo).length > 0;

            let oldCnt =  contentRef.current.alupData.length;
            let newAlupData = [... contentRef.current.alupData];
            if (isSelected) {
                newAlupData = newAlupData.filter(x => x.raceNo != _raceNo);
                ClearBetRaces(_raceNo);
            } else {
                newAlupData.push(initAlupData(contentRef, _raceNo, getAlupBetTypeList( contentRef.current.page)));
            }

            newAlupData.sort(function (a, b) { return a.raceNo - b.raceNo; });
            contentRef.current.alupData = [...newAlupData];
            if ( contentRef.current.alupData.length != oldCnt) {
                let fSet =  contentRef.current.config['ALUP_FORMULA'][ contentRef.current.alupData.length];
                contentRef.current.alupFormula = fSet[0];
            }
            setContent({... contentRef.current});
        } else if ( ["HOME", "HOME_RESULTS", "RESULTS"].includes(contentRef.current.page) ) {
            contentRef.current.raceNo = _raceNo;
            let newUrl = `/${i18n.language}/racing/${ contentRef.current.page.toLowerCase()}/${ contentRef.current.date}/${ contentRef.current.venue}/${ contentRef.current.raceNo}${queryString.toString()!="" ? `?${queryString.toString()}` : ''}`;
            navigate(newUrl, { replace: true });
            reloadMeeting();
        } else {
            if ( _raceNo==contentRef.current.raceNo && isPushing ) {
                return;
            }
            ClearBetSels();
            contentRef.current.isPresales = false

            // if (content.isPresales) {
            //     content.update = Math.random();
            //     content.isLoading = true;
            // }
            contentRef.current.alupData = [];
            contentRef.current.alupFormula = "";
            contentRef.current.raceNo = _raceNo;
            setDefaultDisplayOption( contentRef.current)
            let newUrl = `/${i18n.language}/racing/${ contentRef.current.page.toLowerCase()}/${ contentRef.current.date}/${ contentRef.current.venue}/${ contentRef.current.raceNo}${queryString.toString()!="" ? `?${queryString.toString()}` : ''}`;
            navigate(newUrl, { replace: true });
            Promise.all([
                reloadMeeting(reloadRacingPools),
            ]).then((res) => {
                setContent({... contentRef.current});
            });
        }
        resetInvCal();
    }

    const handleChangePage = (_url, _page, _isAllup) => {
        navigate(_url);
        let betSels = _isAllup ? GetBetSels() : {};

        contentRef.current.page = _page.toUpperCase();
        contentRef.current.pageConfig = GetRacingPageConfigs()[contentRef.current.page];
        contentRef.current.update = Math.random();
        contentRef.current.isPresales = false;
        contentRef.current.alupData = [];
        contentRef.current.alupFormula = "";
        contentRef.current.isLoading = true;
        setDefaultDisplayOption(contentRef.current);
        setContent({... contentRef.current});
        pushData.resubscribe();
        globalContext.updateGlobalState({ betType:  contentRef.current.page, jtcPara: [], allupBoxChecked: false });
        ClearBetSels();

        if (_isAllup) SetBetSels(betSels);
    }

    const handleRefreshButton = () => {
        manualReload();
    }

    const handleJTCCheckbox = (_type, _race, _col, _no, isChecked) => {
        if (globalContext.globalRef.current.allupBoxChecked) {
            if (isChecked) {
                if (_type != 'WIN')
                    SetBetSelect('WIN', _race, _col, _no, false);
                if (_type != 'PLA')
                    SetBetSelect('PLA', _race, _col, _no, false);
                if (_type != 'W-P')
                    SetBetSelect('W-P', _race, _col, _no, false);
            }

            resetAllUpFormula();
        }
    }

    const resetAlupData = (args) => {
        let newAlupData =  contentRef.current.alupData;
        if ( args.idx!=null ) {
            newAlupData[args.idx].betType = args.betType ? args.betType : newAlupData[args.idx].betType;
            newAlupData[args.idx].subType = args.subType ? args.subType : 'S';
            newAlupData[args.idx].maxLeg = args.maxLeg ? args.maxLeg : newAlupData[args.idx].maxLeg;
        }
        else if ( args.data!=null ) {
            newAlupData.push(args.data);
        }
        contentRef.current.alupData = [...newAlupData];
        setContent({... contentRef.current});
    }

    const resetAllUpFormula = () => {
        let raceCnt = 0;
        switch ( contentRef.current.page) {
            case "JTCOMBO":
            case "CWAALLALUP":
                let obj = GetJTCSelectdRace();
                let oldCnt =  contentRef.current.alupData;
                contentRef.current.alupData = [];
                obj.forEach(x => {
                    contentRef.current.alupData.push({ raceNo: x.raceNo, betType: x.bTypes, subType: '', maxLeg: 6});
                });
                if ( contentRef.current.alupData.length != oldCnt) {
                    let fSet = [];
                    if ( contentRef.current.alupData.length <=6 ) {
                        fSet =  contentRef.current.config['ALUP_FORMULA'][ contentRef.current.alupData.length];
                    }
                    contentRef.current.alupFormula = fSet[0];
                }
                setContent({... contentRef.current});
                break;
        }
    }

    useEffect(() => {
        let hasFOSelectedBet = false
        Object.keys(FOSelectedBet).forEach((id) => {
            if (FOSelectedBet[id].checked) hasFOSelectedBet = true
        });
        if (['JKC', 'TNC'].includes(contentRef.current.page)) {
            globalContext.setBottomMenuAddSlipButtonDisable(!hasFOSelectedBet)
        } else {
            globalContext.setBottomMenuAddSlipButtonDisable(contentRef.current.betCount === '-')
        }
    }, [contentRef.current.page, contentRef.current.betCount, FOSelectedBet])

    const processJTCSearch = (jtcPara) => {
        if (jtcPara[0] == 'ALL' && jtcPara[1] == 'ALL') {
            const isLocalMtg=isLocalMeeting(contentRef.current.venue);
            alert(t(`${isLocalMtg?'LB_RC_JTC_CONFIRM_ERR':'LB_RC_JTC_CONFIRM_SIMULCAST_ERR'}`));
        }
        else {
            globalContext.updateGlobalState({ jtcPara: jtcPara });

            ClearBetSels();
            contentRef.current.betCount = '-';
            if(['JTCOMBO'].includes(contentRef.current.page) && contentRef.current.alupFormula) {
                contentRef.current.alupFormula = ''
            }
            setContent({... contentRef.current});
        }
    }

    const racingFuncs = {
        setContent: (c) => { setContent(c) },
        resetAlupData: (c) => { resetAlupData(c) },
        resetAllUpFormula: (c) => { resetAllUpFormula() },
        resetInvCal: () => { resetInvCal() },
        reloadData: (c) => {
            reloadRacingPools();
            pushData.resubscribe();
        }
    }
    if (content.initLoading) {
        return <Loading />;
    } else {
        let pageConfig = contentRef.current.pageConfig || {};
        let pmPoolDefined = isPoolDefined(contentRef.current.page, content?.raceNo, contentRef.current.meeting?.poolInvs)
        let foPoolDefined = isFOPoolDefined(contentRef.current.page, contentRef.current.meeting?.foPools);
        let poolDefined = pmPoolDefined || foPoolDefined || isRacingFOPage(contentRef.current.page);

        return (

            <RacingContext.Provider value={{ handleRefreshButton, content,contentRef,setContent, racingFuncs, windowSize, pageConfig, FOSelectedBet, setFOSelectedBet, foUserContent }} >
                <div>
                    <Helmet>
                        <title>{t('LB_RACING_TITLE')}</title>
                        <link rel="manifest" href={i18n.language=="en" ? "/manifest/manifest_hrE.json" : "/manifest/manifest_hr.json"} />
                        <meta name="description" content={t('LB_RACING_DESC')} />
                    </Helmet>
                    <ImportantNotice product={curProduct} enable={pageConfig?.showImportantNotice} />
                    <div className="rcMain">
                        <Banner Product={curProduct} enable={pageConfig.showPromoBanner} />
                        {!windowSize.isMobileSize && <TopMenu Product={curProduct} onPageChange={handleChangePage} />}
                        <div className="rcMiddle">
                            {!windowSize.isMobileSize && <LeftMenu Product={curProduct} lang={i18n.language} onPageChange={handleChangePage} type={contentRef.current.page} />}
                            {contentRef.current?.meetingList?.length>0 ?
                                <div className="rcOddsTable" id="rcOddsTable">
                                    <header className={contentRef.current.page == "6UP" ? "SIXUP" : contentRef.current.page}>
                                        {!windowSize.isMobileSize && <RacingTitle onClickAllUpBtn={handleAllUpClick} onClickRefreshButton={handleRefreshButton}
                                            onPageChange={handleChangePage} poolDefined={pmPoolDefined} foPoolDefined={foPoolDefined} />}
                                            <Sticky innerZ={999} enabled={windowSize.isMobileSize}>
                                            <MeetingButton poolDefined={poolDefined} handleVenueClick={handleVenueClick} handleRaceNoClick={handleRaceNoClick}  page={contentRef.current.page}/>
                                            </Sticky>
                                        <MeetingInfo isMobileSize={windowSize.isMobileSize} onPageChange={handleChangePage} poolDefined={pmPoolDefined} foPoolDefined={foPoolDefined} />

                                        {poolDefined && pageConfig.showJTCombo ? <JTComboSearch processJTCSearch={processJTCSearch} /> : null}
                                        {pageConfig.showFOInfo ? <FOMenu /> : null}
                                        {!content.isLoading ? <NoBettingAndVoidedMsgUI content={content} /> : null}
                                    </header>
                                    {content.isLoading ? <Loading/> : <>
                                        {poolDefined ? <RedirectPage handleJTCCheckbox={handleJTCCheckbox} /> : null}
                                        {!content.isLoading && !poolDefined ? <div className="pool-not-defined">{getPoolNotDefinedMsg(content, t)}</div> : null}
                                    </>}
                                </div>
                            :  <div className='noInfoTip'>{content.isLoading ? <Loading/> : t('LB_RC_JTC_NOINFO')}</div>}
                        </div>
                    </div>
                </div>
                {window.globalConfig.Show_Moblie_Home_PageTooltips && <MoblieHomePageTooltips prod={'racing'} page={content.page}/>}
            </RacingContext.Provider>
        )
    }
});
export default RacingPage;