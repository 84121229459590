import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useWindowSize } from '../Common/CommonHooks';
import DragOnPC from './ui-components/DragOnPC';

const CustomScrollbars = (props) => {
    return <Scrollbars autoHide {...props} />;
};

export function RCMeetingSlider({ className, venue, isMobileSize, children, slideIndex }) {
    const sliderRef = useRef();
    const childrenLength = children.length;
    const sliderDom = document.querySelector('.raceNoSliderCon');
    const curSliderLength = sliderDom ? Math.floor((sliderDom.clientWidth + 4) / 48) : null; // 右边距 4px
    useEffect(() => {
        const slideIndexDom = document.querySelector(`#raceno_${slideIndex}`);
        slideIndexDom?.scrollIntoView({block: "nearest", inline: "nearest" });
    }, [venue, childrenLength, curSliderLength]);

    return (
        <div className="raceNoSliderCon">
            {curSliderLength !== null ? (
                window.matchMedia("(pointer:coarse)").matches || curSliderLength >= childrenLength ? (
                    <div className={`raceNoSlider RCMeetingSlider scroll-none ${className ? className : ''}`}>
                        {children}
                    </div>
                ) : (
                    <DragOnPC>{children}</DragOnPC>
                )
            ) : null}
        </div>
    );
}

RCMeetingSlider.defaultProps = {
    slideIndex: 0
};

export function VenueSlider(props) {
    const { children } = props;
    const { isMobileSize } = useWindowSize();
    const size = children?.length || 0;
    const widthSize = !isMobileSize || children?.length < 3 ? (36 + 8) * size - 8 : (36 + 8) * 2 + 18;
    const width1 = ['JKC', 'TNC', 'CWAALLALUP', 'JTCOMBO'].includes(props.page) ? 'auto' : widthSize + 8;
    const width2 = ['JKC', 'TNC', 'CWAALLALUP', 'JTCOMBO'].includes(props.page) ? 'auto' : widthSize;
    return (
        <div className="slider-container-venue scroll-none" style={{ minWidth: width1, paddingLeft: '8px' }}>
            <div
                className="scroll-none venue-list"
                style={{
                    width: width2,
                    margin: '0 auto',
                    display: 'flex',
                    justifyContent: 'space-between',
                    overflow: 'auto'
                }}
            >
                {children}
            </div>
        </div>
    );
}

export function TournamentSlider(props) {
    const slider = useRef();

    var settings = {
        dots: true,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false
    };
    return (
        <div className="slider-container-tournament">
            <Slider {...settings} ref={slider}>
                {props.children}
            </Slider>
        </div>
    );
}

export function RebateCalcMeetingSlider(props) {
    const { className, children  }=props;
    const {isMobileSize}=useWindowSize();

    return (
        <div className="raceNoSliderCon">
            { window.matchMedia("(pointer:coarse)").matches ? (
                <div className={`raceNoSlider RCMeetingSlider scroll-none ${className ? className : ''}`}>
                    {children}
                </div>
            ) : (
                <DragOnPC>{children}</DragOnPC>
            ) }
        </div>
    );
}

RebateCalcMeetingSlider.defaultProps = {
    slideIndex: 0
};
