import React, { createContext } from 'react';
import moment from 'moment';
import { getCIAMJwtToken, getPushToken } from '../BetSlip/LoginIBHooks';
import i18n from '../../i18n';
import { isMobileSize } from './CommonHooks';

export function getProductFromURL() {
    const pathname = window.location.pathname;
    let params = pathname.split('/');
    let pd = 'GL';

    if (params.length > 2 && params[2] != null) {
        switch (params[2].toLowerCase()) {
            case 'racing':
                return 'HR';
            case 'football':
                return 'FB';
            case 'marksix':
                return 'LY';
        }
    }
    return pd;
}

export const getOldUrl = (lang) => {
    switch (getProductFromURL()) {
        case 'HR':
            return `${window.globalConfig.JCBW_URL}/racing/index.aspx?lang=${lang}`;
        case 'FB':
            return `${window.globalConfig.JCBW_URL}/football/index.aspx?lang=${lang}`;
        case 'LY':
            return `${window.globalConfig.JCBW_URL}/marksix/index.aspx?lang=${lang}`;
    }
    return `${window.globalConfig.JCBW_URL}/index.aspx?lang=${lang}`;
};

export function getScreenInfo() {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
        isMobileSize: window.innerWidth < 769
    };
}

export function formatDateTime(dt, lang = '', shortDayEn = false ,formatDate='DD/MM/YYYY') {
    if (!dt) return dt;
    let dayEn = shortDayEn ?
    ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'] :
    ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let dayCh = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
    let date = moment(dt.replace('+0800', '').replace('+08:00', ''));
    let dateFormat = date.format(formatDate);
    let timeFormat = date.format('HH:mm');
    let dayFormat;
    if (lang == 'ch') {
        dayFormat = dayCh[date.day()];
    }
    if (lang == 'en') {
        dayFormat = dayEn[date.day()];
    }

    return [dateFormat, timeFormat, dayFormat];
}

export function formatDateObj(dt) {
    let date = moment(dt);
    let dateFormat = date.format('YYYY-MM-DD');
    let timeFormat = date.format('HH:mm');
    return [dateFormat, timeFormat];
}

export function dateAdd(dtStr, noOfDays) {
    return formatDateObj(moment(dtStr).add(noOfDays, 'days'))[0];
}

export function lblBr(lbl) {
    if (lbl == null) return [];
    let newArr = [];
    let arr = lbl.split('{nl}');
    arr.forEach((ele, idx) => {
        if (idx > 0) newArr.push(<br />);
        newArr.push(ele);
    });
    return newArr;
}

export const alupFormulaItem = {
    '2x1': '12',
    '2x3': '1#2#12',
    '3x1': '123',
    '3x3': '12#13#23',
    '3x4': '12#13#23#123',
    '3x6': '1#2#3#12#13#23',
    '3x7': '1#2#3#12#13#23#123',
    '4x1': '1234',
    '4x4': '123#124#134#234',
    '4x5': '123#124#134#234#1234',
    '4x6': '12#13#14#23#24#34',
    '4x10': '12#13#14#23#24#34#1#2#3#4',
    '4x11': '123#124#134#234#1234#12#13#14#23#24#34',
    '4x14': '1#2#3#4#123#124#134#234#12#13#14#23#24#34',
    '4x15': '1#2#3#4#123#124#134#234#12#13#14#23#24#34#1234',
    '5x1': '12345',
    '5x5': '1234#1235#1245#1345#2345',
    '5x6': '12345#1234#1235#1245#1345#2345',
    '5x10': '12#13#14#15#23#24#25#34#35#45',
    '5x15': '12#13#14#15#23#24#25#34#35#45#1#2#3#4#5',
    '5x16': '12345#1234#1235#1245#1345#2345#123#124#125#134#135#145#234#235#245#345',
    '5x20': '12#13#14#15#23#24#25#34#35#45#123#124#125#134#135#145#234#235#245#345',
    '5x25': '1#2#3#4#5#12#13#14#15#23#24#25#34#35#45#123#124#125#134#135#145#234#235#245#345',
    '5x26': '12345#1234#1235#1245#1345#2345#123#124#125#134#135#145#234#235#245#345#12#13#14#15#23#24#25#34#35#45',
    '5x30': '1234#1235#1245#1345#2345#123#124#125#134#135#145#234#235#245#345#12#13#14#15#23#24#25#34#35#45#1#2#3#4#5',
    '5x31': '12345#1234#1235#1245#1345#2345#123#124#125#134#135#145#234#235#245#345#12#13#14#15#23#24#25#34#35#45#1#2#3#4#5',
    '6x1': '123456',
    '6x6': '12345#12346#13456#12456#12356#23456',
    '6x7': '123456#12345#12346#13456#12456#12356#23456',
    '6x15': '12#13#14#15#16#23#24#25#26#34#35#36#45#46#56',
    '6x20': '123#124#125#126#134#135#136#145#146#156#234#235#236#245#246#256#345#346#356#456',
    '6x21': '12#13#14#15#16#23#24#25#26#34#35#36#45#46#56#1#2#3#4#5#6',
    '6x22': '123456#12345#12346#13456#12456#12356#23456#1234#1235#1236#1245#1246#1256#1345#1346#1356#1456#2345#2346#2356#2456#3456',
    '6x35': '123#124#125#126#134#135#136#145#146#156#234#235#236#245#246#256#345#346#356#456#12#13#14#15#16#23#24#25#26#34#35#36#45#46#56',
    '6x41': '123#124#125#126#134#135#136#145#146#156#234#235#236#245#246#256#345#346#356#456#12#13#14#15#16#23#24#25#26#34#35#36#45#46#56#1#2#3#4#5#6',
    '6x42': '123456#12345#12346#13456#12456#12356#23456#1234#1235#1236#1245#1246#1256#1345#1346#1356#1456#2345#2346#2356#2456#3456#123#124#125#126#134#135#136#145#146#156#234#235#236#245#246#256#345#346#356#456',
    '6x50': '1234#1235#1236#1245#1246#1256#1345#1346#1356#1456#2345#2346#2356#2456#3456#123#124#125#126#134#135#136#145#146#156#234#235#236#245#246#256#345#346#356#456#12#13#14#15#16#23#24#25#26#34#35#36#45#46#56',
    '6x56': '1234#1235#1236#1245#1246#1256#1345#1346#1356#1456#2345#2346#2356#2456#3456#123#124#125#126#134#135#136#145#146#156#234#235#236#245#246#256#345#346#356#456#12#13#14#15#16#23#24#25#26#34#35#36#45#46#56#1#2#3#4#5#6',
    '6x57': '123456#12345#12346#13456#12456#12356#23456#1234#1235#1236#1245#1246#1256#1345#1346#1356#1456#2345#2346#2356#2456#3456#123#124#125#126#134#135#136#145#146#156#234#235#236#245#246#256#345#346#356#456#12#13#14#15#16#23#24#25#26#34#35#36#45#46#56',
    '6x62': '12345#12346#13456#12456#12356#23456#1234#1235#1236#1245#1246#1256#1345#1346#1356#1456#2345#2346#2356#2456#3456#123#124#125#126#134#135#136#145#146#156#234#235#236#245#246#256#345#346#356#456#12#13#14#15#16#23#24#25#26#34#35#36#45#46#56#1#2#3#4#5#6',
    '6x63': '123456#12345#12346#13456#12456#12356#23456#1234#1235#1236#1245#1246#1256#1345#1346#1356#1456#2345#2346#2356#2456#3456#123#124#125#126#134#135#136#145#146#156#234#235#236#245#246#256#345#346#356#456#12#13#14#15#16#23#24#25#26#34#35#36#45#46#56#1#2#3#4#5#6',
    '7x1': '1234567',
    '7x7': '123456#123457#123467#123567#124567#134567#234567',
    '7x8': '123456#123457#123467#123567#124567#134567#234567#1234567',
    '7x21': '12345#12346#12347#12356#12357#12367#12456#12457#12467#12567#13456#13457#13467#13567#14567#23456#23457#23467#23567#24567#34567',
    '7x35': '1234#1235#1236#1237#1245#1246#1247#1256#1257#1267#1345#1346#1347#1356#1357#1367#1456#1457#1467#1567#2345#2346#2347#2356#2357#2367#2456#2457#2467#2567#3456#3457#3467#3567#4567',
    '7x120':
        '12#13#14#15#16#17#23#24#25#26#27#34#35#36#37#45#46#47#56#57#67#123#124#125#126#127#134#135#136#137#145#146#147#156#157#167#234#235#236#237#245#246#247#256#257#267#345#346#347#356#357#367#456#457#467#567#1234#1235#1236#1237#1245#1246#1247#1256#1257#1267#1345#1346#1347#1356#1357#1367#1456#1457#1467#1567#2345#2346#2347#2356#2357#2367#2456#2457#2467#2567#3456#3457#3467#3567#4567#12345#12346#12347#12356#12357#12367#12456#12457#12467#12567#13456#13457#13467#13567#14567#23456#23457#23467#23567#24567#34567#123456#123457#123467#123567#124567#134567#234567#1234567',
    '7x127':
        '1#2#3#4#5#6#7#12#13#14#15#16#17#23#24#25#26#27#34#35#36#37#45#46#47#56#57#67#123#124#125#126#127#134#135#136#137#145#146#147#156#157#167#234#235#236#237#245#246#247#256#257#267#345#346#347#356#357#367#456#457#467#567#1234#1235#1236#1237#1245#1246#1247#1256#1257#1267#1345#1346#1347#1356#1357#1367#1456#1457#1467#1567#2345#2346#2347#2356#2357#2367#2456#2457#2467#2567#3456#3457#3467#3567#4567#12345#12346#12347#12356#12357#12367#12456#12457#12467#12567#13456#13457#13467#13567#14567#23456#23457#23467#23567#24567#34567#123456#123457#123467#123567#124567#134567#234567#1234567',
    '8x1': '12345678',
    '8x8': '1234567#1234568#1234578#1234678#1235678#1245678#1345678#2345678',
    '8x9': '12345678#1234567#1234568#1234578#1234678#1235678#1245678#1345678#2345678',
    '8x28': '123456#123457#123458#123467#123468#123478#123567#123568#123578#123678#124567#124568#124578#124678#125678#134567#134568#134578#134678#135678#145678#234567#234568#234578#234678#235678#245678#345678',
    '8x56': '12345#12346#12347#12348#12356#12357#12358#12367#12368#12378#12456#12457#12458#12467#12468#12478#12567#12568#12578#12678#13456#13457#13458#13467#13468#13478#13567#13568#13578#13678#14567#14568#14578#14678#15678#23456#23457#23458#23467#23468#23478#23567#23568#23578#23678#24567#24568#24578#24678#25678#34567#34568#34578#34678#35678#45678',
    '8x70': '1234#1235#1236#1237#1238#1245#1246#1247#1248#1256#1257#1258#1267#1268#1278#1345#1346#1347#1348#1356#1357#1358#1367#1368#1378#1456#1457#1458#1467#1468#1478#1567#1568#1578#1678#2345#2346#2347#2348#2356#2357#2358#2367#2368#2378#2456#2457#2458#2467#2468#2478#2567#2568#2578#2678#3456#3457#3458#3467#3468#3478#3567#3568#3578#3678#4567#4568#4578#4678#5678',
    '8x247':
        '12#13#14#15#16#17#18#23#24#25#26#27#28#34#35#36#37#38#45#46#47#48#56#57#58#67#68#78#123#124#125#126#127#128#134#135#136#137#138#145#146#147#148#156#157#158#167#168#178#234#235#236#237#238#245#246#247#248#256#257#258#267#268#278#345#346#347#348#356#357#358#367#368#378#456#457#458#467#468#478#567#568#578#678#12345#12346#12347#12348#12356#12357#12358#12367#12368#12378#12456#12457#12458#12467#12468#12478#12567#12568#12578#12678#13456#13457#13458#13467#13468#13478#13567#13568#13578#13678#14567#14568#14578#14678#15678#23456#23457#23458#23467#23468#23478#23567#23568#23578#23678#24567#24568#24578#24678#25678#34567#34568#34578#34678#35678#45678#1234#1235#1236#1237#1238#1245#1246#1247#1248#1256#1257#1258#1267#1268#1278#1345#1346#1347#1348#1356#1357#1358#1367#1368#1378#1456#1457#1458#1467#1468#1478#1567#1568#1578#1678#2345#2346#2347#2348#2356#2357#2358#2367#2368#2378#2456#2457#2458#2467#2468#2478#2567#2568#2578#2678#3456#3457#3458#3467#3468#3478#3567#3568#3578#3678#4567#4568#4578#4678#5678#12345678#1234567#1234568#1234578#1234678#1235678#1245678#1345678#2345678#123456#123457#123458#123467#123468#123478#123567#123568#123578#123678#124567#124568#124578#124678#125678#134567#134568#134578#134678#135678#145678#234567#234568#234578#234678#235678#245678#345678',
    '8x255':
        '1#2#3#4#5#6#7#8#12#13#14#15#16#17#18#23#24#25#26#27#28#34#35#36#37#38#45#46#47#48#56#57#58#67#68#78#123#124#125#126#127#128#134#135#136#137#138#145#146#147#148#156#157#158#167#168#178#234#235#236#237#238#245#246#247#248#256#257#258#267#268#278#345#346#347#348#356#357#358#367#368#378#456#457#458#467#468#478#567#568#578#678#12345#12346#12347#12348#12356#12357#12358#12367#12368#12378#12456#12457#12458#12467#12468#12478#12567#12568#12578#12678#13456#13457#13458#13467#13468#13478#13567#13568#13578#13678#14567#14568#14578#14678#15678#23456#23457#23458#23467#23468#23478#23567#23568#23578#23678#24567#24568#24578#24678#25678#34567#34568#34578#34678#35678#45678#1234#1235#1236#1237#1238#1245#1246#1247#1248#1256#1257#1258#1267#1268#1278#1345#1346#1347#1348#1356#1357#1358#1367#1368#1378#1456#1457#1458#1467#1468#1478#1567#1568#1578#1678#2345#2346#2347#2348#2356#2357#2358#2367#2368#2378#2456#2457#2458#2467#2468#2478#2567#2568#2578#2678#3456#3457#3458#3467#3468#3478#3567#3568#3578#3678#4567#4568#4578#4678#5678#12345678#1234567#1234568#1234578#1234678#1235678#1245678#1345678#2345678#123456#123457#123458#123467#123468#123478#123567#123568#123578#123678#124567#124568#124578#124678#125678#134567#134568#134578#134678#135678#145678#234567#234568#234578#234678#235678#245678#345678'
};

export function nCr(n, r) {
    return Math.round(factorial(n) / (factorial(r) * factorial(n - r)));
}

export function nPr(n, r) {
    return Math.round(factorial(n) / factorial(n - r));
}

export function factorial(n) {
    if (n <= 1) return 1;
    return n * factorial(n - 1);
}

export function formatNumber(num, decimalNum, showDecimalZero, bolParens, bolCommas) {
    if (isNaN(parseInt(num))) return 'NaN';

    var tmpNum = num;
    var iSign = num < 0 ? -1 : 1; // Get sign of number

    // Adjust number so only the specified number of numbers after
    // the decimal point are shown.
    tmpNum *= Math.pow(10, decimalNum);
    tmpNum = Math.round(Math.abs(tmpNum));
    tmpNum /= Math.pow(10, decimalNum);

    // Create a string object to do our formatting on
    var tmpNumStr = showDecimalZero ? tmpNum.toFixed(decimalNum) : new String(tmpNum);

    // See if we need to put in the commas
    if (bolCommas && (num >= 1000 || num <= -1000)) {
        var iStart = tmpNumStr.indexOf('.');
        if (iStart < 0) iStart = tmpNumStr.length;

        iStart -= 3;
        while (iStart >= 1) {
            tmpNumStr = tmpNumStr.substring(0, iStart) + ',' + tmpNumStr.substring(iStart, tmpNumStr.length);
            iStart -= 3;
        }
    }

    // See if we need to use parenthesis
    if (bolParens && num < 0) tmpNumStr = '(' + tmpNumStr.substring(1, tmpNumStr.length) + ')';

    if (iSign == -1) {
        tmpNumStr = '-' + tmpNumStr;
    }
    return tmpNumStr; // Return our formatted string!
}

export function bin2String(array) {
    // var str = [];
    // for (var i=0; i<foo.length; ++i) {
    //     str.push(String.fromCharCode(foo[i]));
    // }
    // return str.join('');
    var out, i, len, c;
    var char2, char3;
    out = '';
    len = array.length;
    i = 0;
    while (i < len) {
        c = array[i++];
        switch (c >> 4) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                // 0xxxxxxx
                out += String.fromCharCode(c);
                break;
            case 12:
            case 13:
                // 110x xxxx 10xx xxxx
                char2 = array[i++];
                out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
                break;
            case 14:
                // 1110 xxxx 10xx xxxx 10xx xxxx
                char2 = array[i++];
                char3 = array[i++];
                out += String.fromCharCode(((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0));
                break;
        }
    }
    return out;
}

export const getMqttToken = (isHigh, jwt) => {
    return `OPENID~${isHigh ? window.globalConfig.SOLACE_OAUTH_PROVIDER : 'CIAM_NORMAL'}~${jwt}~${jwt}`;
};

export const getNotLoginMqttToken = (jwt) => {
    return `OPENID~CIAM_NORMAL~${jwt}~${jwt}`;
};

export const getMqttOptions = (clientId, isHigh, jwt) => {
    return {
        keepalive: 300,
        clientId: clientId,
        protocolId: 'MQTT',
        username: 'jcbw2_4e_user',
        password: getMqttToken(isHigh, jwt),
        protocolVersion: 5,
        clean: true,
        reconnectPeriod: 5000,
        connectTimeout: 30 * 1000
    };
};

export const getNotLoginMqttOptions = (clientId, jwt) => {
    return {
        keepalive: 60,
        clientId: clientId,
        protocolId: 'MQTT',
        username: 'jcbw2_4e_user',
        password: getNotLoginMqttToken(jwt),
        protocolVersion: 5,
        clean: true,
        reconnectPeriod: 5000,
        connectTimeout: 30 * 1000
    };
};

export const textFormatAmountFn = (text, num = 2, reUnd = true, reZero = false) => {
    if (text !== 0 && (!text || isNaN(text))) return reUnd ? undefined : reZero ? 0 : text;
    let newNum = text - 0;
    return newNum.toLocaleString('en-US', { minimumFractionDigits: num, maximumFractionDigits: num });
};

export function formatNumberDecimals(number, decimals) {
    if(isNaN(Number(number))) return number;
    number = number + '';
    if(number.includes('.') || decimals) {
      const parts = number.split('.');
      const integerPart = parts[0];
      let decimalPart = parts[1] || '';
      if(decimals) {
        decimalPart = decimalPart.padEnd(decimals, "0")
      }
      return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + decimalPart;
    } else {
        return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

}

export const isHasPropertyInObj = (obj, key) => {
    if (Array.isArray(key)) {
        for (let i = 0; i < key.length; i++) {
            const curStr = key[i];
            if (!(curStr in obj)) {
                return false;
            }
        }
        return true;
    } else {
        return key in obj;
    }
};

export const numberDesensitizeFn = (bankAccountNo) => {
    // 號碼脫敏 copy from maskBankAccount()
    bankAccountNo = bankAccountNo + '';
    if (bankAccountNo) {
        const first = bankAccountNo.substr(0, 3);
        const last = bankAccountNo.substr(bankAccountNo.length - 2);
        const mask = bankAccountNo.substr(3, bankAccountNo.length - 3 - 2).replace(/\d/g, '*');

        return first + mask + last;
        // retrurn bankAccountNo.replace(/(?<=.{3})\d(?=.{2})/g, '*')
    }
    return '';
};

export const isEoD = () => {
    if (window.globalConfig.IS_PREVIEW || sessionStorage.getItem('bypassEodCode') != null) {
        return false;
    }

    const msgId = sessionStorage.getItem('OOSMsgId');

    if (msgId == null) {
        return false;
    }
    if (msgId >= 21 && msgId <= 25) {
        return true;
    }
    return false;
};

export const isLiteEoD = () => {
    if (window.globalConfig.IS_PREVIEW) {
        return false;
    }

    const msgId = sessionStorage.getItem('OOSMsgId');

    if (msgId == null) {
        return false;
    }

    if (msgId !== '0') {
        return true;
    }

    return false
};

export const isLiteSystemMaintenanceEoD = () => {
    const msgId = sessionStorage.getItem('OOSMsgId');

    return isLiteEoD() && (msgId >= 21 && msgId <= 25);
}

export const isPmuEoD = () => {
    if (window.globalConfig.IS_PREVIEW) {
        return false;
    }

    const msgId = sessionStorage.getItem('OOSMsgId');

    if (msgId == null) {
        return false;
    }
    if (msgId >= 11 && msgId <= 17) {
        return true;
    }
    return false;
};

export const eodMessage = () => {
    return i18n.language === 'ch' ? sessionStorage.getItem('OOSChiContent') : sessionStorage.getItem('OOSEngContent');
};

export const flexiFontSize = (val) => {
    if (val?.length > 9) {
        return `${20 - val.length}px`;
    }
    if (val?.length > 7) {
        return `${21 - val.length}px`;
    }
    return `15px`;
};

export const unitbetFontSize = (val) => {
    if (val?.length > 11) {
        return `9px`;
    }
    if (val?.length > 9) {
        return `11px`;
    }
    if (val?.length > 7) {
        return `13px`;
    }
    return `15px`;
};

// export const isMobileAndMax1023 = (str) => {
//     return window.matchMedia("(pointer:coarse)").matches && window.matchMedia("only screen and (max-width: 1023px)").matches
// };

export const consoleLog = (...data) => {
    if (window.globalConfig.ENABLE_LOG) {
        console.log(...data);
    }
};

export function sprintf(str) {
    for (let i = 1; i < arguments.length; i++) {
        str = str.replace(/%s/, arguments[i]);
    }
    return str;
}

export const iOSMobilePWAMode = () => {
    const iOS = window.navigator?.userAgent && /iPad|iPhone|iPod/.test(window.navigator.userAgent);
    const isPWAMode = (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone)
    return (iOS && isPWAMode);
};

export const getLastUpdate = (ts) => {
    let serverTime= sessionStorage.getItem('LastUpdate');
    let respDate = new Date();
    if(!serverTime){
        return respDate;
    }
    const stArr = serverTime.split(` `);
    let date=stArr[0].split('/')
    let time=stArr[1].split(':')
    respDate.setFullYear(Number(date[2]));
    respDate.setDate(Number(date[1]));
    respDate.setMonth(Number(date[0])-1);
    respDate.setHours(Number(time[0]));
    respDate.setMinutes(Number(time[1]));
    respDate.setSeconds(Number(time[2]));
    return respDate;
};

export const scrollTo = (left = 0, top = 0, behavior = 'instant', time = 3, hideMobileTop = true) => {
    if(document.querySelector('.fMenuTR-mobile-con')?.clientHeight && hideMobileTop && !isHomePage() && isMobileSize() && top === 0) {
        top = document.querySelector('.fMenuTR-mobile-con')?.clientHeight
    }
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollBar = document.querySelector('.StandardPage-scrollbars > div:first-child'); // pc
    // if (c > top || (scrollBar && scrollBar.scrollTop > top)) {
    // window.requestAnimationFrame(scrollTo);
    window.scrollTo({
        top,
        left,
        behavior
    });
    if(scrollBar){
        scrollBar.scrollLeft = left;
        scrollBar.scrollTop = top;
    }
    if (time !== 0) {
        setTimeout(() => {
            // ios bug
            scrollTo(left, top,  behavior, --time);
        }, 300);
    }
    // }
};


export const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export const windowOpen = (link) => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.setAttribute('href', link);
    a.setAttribute('target', '_blank');
    a.click();
    document.body.removeChild(a);
}

const productType = ['racing', 'marksix', 'football', 'home']
const homeType = ['home', 'home_results']
export const isHomePage = () => {
    const pathname = window.location.pathname.split('/')
    let begin = 2
    if(pathname[1] === 'betlite') {
        begin = 3
    }
    if(productType.includes(pathname[begin]) && (homeType.includes(pathname[begin + 1]) || !pathname[begin + 1])) {
        return true
    } else {
        return false
    }
}